<template>
  <div class="my-container">
    <logout-btn />

    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      "
    >
      <H1>EVENTS</H1>
      <div style="display: flex">
        <lob-button
          @click="this.$router.push('/event_stats')"
          style="height: fit-content"
        >
          EVENTS' STATS
        </lob-button>
        <lob-button
          @click="this.$router.push('/create_event')"
          style="height: fit-content; margin-left: 5rem"
        >
          CREATE NEW EVENT
        </lob-button>
      </div>
    </div>
    <P>
      These are all existing Events in the database. (In the future, we'll
      automatically prune the ones that're already over.) For now, you can
      manually delete each Event. You can also change the Name, Location, Start
      Date, and End Date of each existing event. The Location dropdown consists
      of existing Cities in the database, which are listed below the Events
      table.
    </P>
    <P>
      Click on "SUBMITTED RESPONSES" to see what users have completed the
      corresponding row's game and who have claimed their prizes.
    </P>
    <existing-events
      :events="events"
      :cities="cities"
      :updatable="true"
      @deleted-event="updateEvents"
    />

    <H1>CITIES</H1>
    <existing-cities
      :cities="cities"
      :events="events"
      @deleted-city="updateCities"
    />
  </div>
</template>

<script>
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import ExistingCities from "./ExistingCities.vue";
import ExistingEvents from "./ExistingEvents.vue";
import LogoutBtn from "./LogoutBtn.vue";

export default {
  name: "EventsPage",
  components: {
    ExistingCities,
    ExistingEvents,
    LogoutBtn,
  },
  data() {
    return {
      events: [],
      cities: [],
    };
  },
  async mounted() {
    this.events = await this.query("events");
    this.cities = await this.query("cities");
  },
  methods: {
    async query(coll) {
      const querySnapshot = await getDocs(collection(db, coll));
      const res = [];
      querySnapshot.forEach((doc) => {
        res.push([doc.id, doc.data()]);
      });
      return res;
    },
    updateEvents(ind) {
      // for when you've deleted an event and need to refresh the list of existing events
      this.events.splice(ind, 1);
    },
    updateCities(ind) {
      // for when you've deleted a city and need to refresh the list of existing cities
      this.cities.splice(ind, 1);
    },
  },
};
</script>
