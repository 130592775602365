<template>
  <div
    class="text-gray-900"
    v-bind:style="{
      'background-color': ind % 2 ? '#F8F9FA' : '#C5D6E6',
      padding: '1rem',
    }"
  >
    <H3>{{ this.$store.state.admin.landmarks[landmarkInd].name }}</H3>
    <P>{{ this.$store.state.admin.answers[this.landmarkInd] }}</P>
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
      "
    >
      <Card
        v-for="(photo, photoInd) in photos"
        :key="photoInd"
        :ind="photoInd"
        :url="photo.url"
        :html_attributions="photo.html_attributions"
        :selectable="false"
        class="my-card"
        v-bind:style="{
          margin: '1rem',
          'background-color': ind % 2 ? '#F7F9FA' : '#E3F1FB',
        }"
      />
    </div>
  </div>
</template>

<script>
import Card from "./Card.vue";

export default {
  name: "Landmark",
  components: {
    Card,
  },
  props: {
    landmarkInd: {
      type: Number,
      required: true,
    },
    ind: {
      type: Number,
      required: true,
    },
  },
  computed: {
    photos() {
      const selectedPhotosInds =
        this.$store.state.admin.selectedPhotos[this.landmarkInd];
      const res = [];
      selectedPhotosInds.forEach((ind) => {
        res.push(this.$store.state.admin.photos[this.landmarkInd][ind]);
      });
      return res;
    },
  },
};
</script>
