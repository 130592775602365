<template>
  <lob-button
    @click="googleSignOut"
    style="position: absolute; right: 2rem; top: 2rem"
  >
    Logout
  </lob-button>
</template>

<script>
export default {
  name: "LogoutBtn",
};
</script>
