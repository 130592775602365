<template>
  <lob-table space="md" class="min-w-full divide-y divide-gray-200">
    <TableHeader>
      <div>Name</div>
      <div>Location</div>
      <div>Start Date</div>
      <div>End Date</div>
      <div>Visible</div>
      <div></div>
    </TableHeader>
    <TableBody>
      <TableRow v-for="(event, ind) in events" :key="ind">
        <div style="width: fit-content">{{name[ind]}}</div>
        <div style="width: fit-content">{{ event[1].city }}</div>
        <div style="width: fit-content">{{ startDate[ind]}}</div>
        <div style="width: fit-content">{{ endDate[ind]}}</div>
        <div style="width: fit-content">{{ isVisible[ind]}}</div>
        <lob-button @click="viewEventURL(event[0])">Edit</lob-button>
      </TableRow>
    </TableBody>
  </lob-table>
</template>

<script>
import { db } from "../../firebase";
import {
  doc,
  deleteDoc,
  updateDoc,
  getDocs,
  query,
  where,
  collection,
} from "firebase/firestore";
const crypto = require("crypto");

export default {
  name: "ExistingEvents",
  data() {
    return {
      startOpen: [],
      endOpen: [],
      eventInd: -1, // event index that should be showing users modal
      deleteInd: -1, // event index that should be showing deletion modal
      viewInd: -1, // event index that should be showing the viewURL modal 
      users: [],
      updateSuccess: false,
      updateErr: false,
    };
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    cities: {
      type: Array,
      required: false,
    },
    updatable: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    events: function (newEvents) {
      this.startOpen = new Array(newEvents).fill(false);
      this.endOpen = new Array(newEvents).fill(false);
    },
  },
  methods: {
    async updateClaimed(userId, userInd) {
      updateDoc(doc(db, "users", userId), {
        claimed: !this.users[userInd].claimed,
      })
        .then(() => {
          this.users[userInd].claimed = !this.users[userInd].claimed;
        })
        .catch((err) => console.error(err));
    },
    async showUsers(ind) {
      // only allow admin to say user has claimed prize on Events page
      if (!this.updatable) return;

      if (ind >= 0) {
        const q = query(
          collection(db, "users"),
          where("event", "==", this.events[ind][1].name)
        );
        const querySnapshot = await getDocs(q);
        const res = [];
        querySnapshot.forEach((doc) => {
          const decipherName = crypto.createDecipheriv(
            process.env.VUE_APP_ENCRYPT_ALGO,
            process.env.VUE_APP_ENCRYPT_KEY,
            process.env.VUE_APP_ENCRYPT_INIT
          );
          const decipherEmail = crypto.createDecipheriv(
            process.env.VUE_APP_ENCRYPT_ALGO,
            process.env.VUE_APP_ENCRYPT_KEY,
            process.env.VUE_APP_ENCRYPT_INIT
          );
          const data = doc.data();
          const decryptedName =
            decipherName.update(data.name, "hex", "utf8") +
            decipherName.final("utf8");

          const decryptedEmail =
            decipherEmail.update(data.email, "hex", "utf8") +
            decipherEmail.final("utf8");

          res.push({
            id: doc.id,
            name: decryptedName,
            email: decryptedEmail,
            claimed: data.claimed,
          });
        });
        this.users = res;
      } else this.users = [];

      this.eventInd = ind; // to signal modal to open
    },
    openDeleteModal(ind) {
      this.deleteInd = ind;
    },
    deleteEvent() {
      deleteDoc(doc(db, "events", this.events[this.deleteInd][0]))
        .then(() => {
          this.$emit(
            "deleted-event",
            this.deleteInd,
            this.events[this.deleteInd][1].name
          );
          this.deleteInd = -1;
        })
        .catch((err) => console.error(err));
    },
    viewEventURL(eventID) {
      this.$router.push('/event/' + eventID)

    },
    updateEvent(ind) {
      updateDoc(doc(db, "events", this.events[ind][0]), {
        name: this.name[ind],
        city: this.dropdownVals[ind],
        startDate: this.startDate[ind],
        endDate: this.endDate[ind]
      })
        .then(() => {
          this.updateSuccess = true;
          setTimeout(() => (this.updateSuccess = false), 2000);
        })
        .catch((err) => {
          this.updateErr = err;
        });
    },
  },
  computed: {
    startDate() {
      return this.events.map(
        (event) => new Date(event[1].startDate.seconds * 1000).toDateString()
      );
    },
    endDate() {
      return this.events.map(
        (event) => new Date(event[1].endDate.seconds * 1000).toDateString()
      );
    },
    isVisible() {
      return this.events.map(function(event) {
        if (!event[1].isVisible) {
          return "NO"
        } else {
          return event[1].isVisible
        }
      })
    },
    name() {
      return this.events.map((event) => event[1].name);
    },
    dropdownVals() {
      return this.events.map((event) => event[1].city);
    },
    options() {
      if (this.cities) return this.cities.map((city) => city[1].city);
      else return [];
    },
  },
};
</script>
