<template>
  <div class="backdrop-end">
    <svg class="wave" viewBox="0 0 428 436" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.56092 722L454.02 722L495 56.8198C315.033 -18.5669 112.369 -18.7285 -67.7171 56.3713L-103 71.085L2.56092 722Z"
        fill="#0154AC"
      />
    </svg>
  </div>
  <div class="end-outer-container">
    <div class="end-inner-container">
      <div class="end-header-container">
        <span>Congrats, you earned {{ points }} points</span>
      </div>
      <div class="image-wrapper-end hidden-for-desktop">
        <img
          class="confetti-tl"
          src="../../assets/confetti-top-left.png"
          alt="Confetti Top Left"
        />
        <img
          class="trophy-logo"
          src="../../assets/tropy-logo.png"
          alt="Gold Trophy"
        />
        <img
          class="confetti-br"
          src="../../assets/confetti-bottom-right.png"
          alt="Confetti Bottom Right"
        />
      </div>
      <div class="end-lower-container">
        <div class="end-lower-inner-container">
          <span>Be sure to enter your information to claim your prize!</span>
        </div>
      </div>
      <lob-button class="end-button" @click="nextPage">
        Enter your info
      </lob-button>
    </div>
  </div>
</template>

<script>
import "./End.css";
export default {
  name: "End",
  emits: ["set-page"],
  data() {
    return {
      name: "",
      email: "",
      submitted: false,
      futureCommsConsent: false,
      points: this.$store.state.game.points,
    };
  },
  methods: {
    nextPage() {
      this.$emit("set-page", "ENTERINFO");
      return;
    },
  },
};
</script>

<style>
.backdrop-end {
  background-color: #f3f5ff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}
</style>
