<template>
  <div
    style="
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      align-items: center;
    "
  >
    <alert v-show="!havePhotos" variant="warning">
      Sorry, but there's no photos available for this landmark from the Google
      Places Library.
    </alert>
    <Card
      v-show="havePhotos"
      v-for="(photo, ind) in photos"
      :key="ind"
      :ind="ind"
      :url="photo.url"
      :html_attributions="photo.html_attributions"
      @processPicSelect="handleSelect"
      :selected="selected.has(ind)"
      :selectable="true"
      style="margin: 0.7rem"
      class="my-card"
    />
  </div>
</template>

<script>
import Card from "./Card.vue";

export default {
  name: "Grid",
  components: {
    Card,
  },
  props: {
    photos: {
      type: Array,
      required: true,
    },
    landmarkInd: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleSelect(cardInd) {
      const selected = this.$store.getters["admin/getSelectedPhotosByLandmark"](
        this.landmarkInd
      );

      if (selected.has(cardInd)) selected.delete(cardInd);
      else selected.add(cardInd);
      this.$store.commit({
        type: "admin/setSelectedPhotosByInd",
        landmarkInd: this.landmarkInd,
        selectedInds: selected,
      });

      if (selected.size >= 3) this.$emit("enough");
    },
  },
  computed: {
    havePhotos() {
      return this.photos && this.photos.length;
    },
    selected() {
      return this.$store.getters["admin/getSelectedPhotosByLandmark"](
        this.landmarkInd
      );
    },
  },
};
</script>
