const adminModule = {
  state() {
    return {
      eventName: null,
      cityState: null,
      startDate: null,
      endDate: null,

      /*
        arr of all landmarks (not just indices) in cityState
        storing here b/c if admin navigates between pages often,
        don't want to keep making the same API calls -- expensive
      */
      landmarks: null,
      /*
        Object of all landmarks admin clicked (not just the ones they selected)
        will need to filter on Confirm page
      */
      answers: {},
      /*
        photo objects pulled with getDetails() req, set in LandmarksTable
        will look like: {
          "1": [{ url, html_attributions, name }, { url, html_attributions, name }, ...],
          "2": ...
        }
        where only landmarks 1 and 2 are shown here
      */
      photos: null,
      /*
        will be arr of int landmark indices
        set on beforeUnmount of LandmarksTable
      */
      selectedLandmarks: null,
      /*
        if selected pics 2, 4, 7 of landmark 4 and pics 3 of landmark 2,
        will look like: {
          "2": Set([3]),
          "4": Set([2, 4, 7]),
        }
        go with an Object since it's more compact than using an array
        and access to an indexed landmark is instantaneous in both cases
        and the values are Sets b/c they're saved as Sets in Grid
        makes it easier to add and delete selected indices
        Note: just b/c a landmark's index is keyed in the Object doesn't mean
        that landmark has been selected
      */
      selectedPhotos: null,
      /*
        the Google Places Library
      */
      service: null,
      /*
        number of questions per user
        originally, could've just been >= numSelectedLandmarks but is now
        equal to numSelectedLandmarks. leaving this variable here anyway in case
        that decision is ever reversed
      */
      numQuestions: null,
      avResults: {},
    };
  },
  getters: {
    getSelectedPhotosByLandmark: (state) => (landmarkInd) => {
      // so Grid can determine whether a photo should be (de)selected
      if (!state.selectedPhotos || !state.selectedPhotos[landmarkInd])
        return new Set();
      return state.selectedPhotos[landmarkInd];
    },
  },
  mutations: {
    setNewEvent(state, payload) {
      state.eventName = payload.eventName;
      state.cityState = payload.cityState;
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    setLandmarks(state, payload) {
      state.landmarks = payload.landmarks;
    },
    setPhotosAnswersAvResults(state, payload) {
      state.photos = payload.photos;
      state.answers = payload.answers;
      state.avResults = payload.avResults;
    },
    setSelectedLandmarks(state, payload) {
      state.selectedLandmarks = payload.selectedLandmarks;
    },
    resetSelectedPhotos(state) {
      state.selectedPhotos = null;
    },
    setSelectedPhotosByInd(state, { landmarkInd, selectedInds }) {
      if (!state.selectedPhotos) {
        const newState = {};
        newState[landmarkInd] = selectedInds;
        state.selectedPhotos = newState;
      } else state.selectedPhotos[landmarkInd] = selectedInds;
    },
    setService(state, payload) {
      state.service = payload.service;
    },
    setNumQuestions(state, payload) {
      state.numQuestions = payload.numQuestions;
    },
  },
  namespaced: true,
};

export default adminModule;
