<template>
  <BackdropWave />
  <div class="my-container container-result container-result-map-question">
    <div class="panel-centered-desktop">
      <div class="container-col-inner">
        <div class="col-desktop">
          <H2 class="header">{{
            match ? "Great job!" : "You have guessed incorrectly."
          }}</H2>
          <div style="text-align: left" class="label-text">You guessed:</div>

          <div class="address-text" id="input-address">
            {{ this.inputFunc() }}
          </div>

          <div style="text-align: left" class="label-text label-padded-top">
            The Correct Address:
          </div>

          <div class="address-text" id="expected-address">
            {{ this.$store.getters["game/getCurrAnswer"]() }}
          </div>

          <span id="blank-space"></span>

          <div style="text-align: left" class="lob-stats">
            USPS Deliverability:
            <p class="stat">
              {{ this.$store.state.game.avRes.deliverability }}
            </p>
          </div>

          <div
            style="text-align: left"
            class="lob-stats"
            id="lob-score"
            :hidden="!this.$store.state.game.avRes.lob_confidence_score.score"
          >
            Lob Confidence Score:
            <p class="stat">
              {{ this.$store.state.game.avRes.lob_confidence_score.score }}%
            </p>
          </div>

          <div class="wrapper-lob-info-box label-padded-top">
            <div class="lob-info-box" id="lob-match">
              <img class="img-indicators" id="img-deliverability" />
              {{ this.deliverabilityMessage() }}
            </div>

            <div class="lob-info-box" id="lob-primary">
              <img
                class="img-indicators"
                id="img-primary"
                :hidden="!this.primaryMessage()"
              />
              {{ this.primaryMessage() }}
            </div>

            <div class="lob-info-box" id="lob-secondary">
              <img
                class="img-indicators"
                id="img-secondary"
                :hidden="!this.secondaryUnitMessage()"
              />
              {{ this.secondaryUnitMessage() }}
            </div>

            <div class="lob-info-box" id="lob-zip">
              <img
                class="img-indicators"
                id="img-zip"
                style="width: fit-content"
                :hidden="!this.compareZips()"
              />
              {{ this.compareZips() }}
            </div>
          </div>
        </div>

        <div class="col-desktop flex-mobile">
          <div class="margin-bottom verify-map">
            <iframe id="map" style="border: 0" allowfullscreen> </iframe>
          </div>
        </div>
      </div>

      <lob-button class="next-button-map" @click="nextPage">
        Next Question ->
      </lob-button>
    </div>
  </div>
</template>

<script>
import "./Result.css";
import BackdropWave from "@/components/shared/BackdropWave";

export default {
  name: "Result",
  components: { BackdropWave },
  emits: ["set-page"],
  data() {
    return {
      photoObjs: [],
      match: this.$store.state.game.match.val,
    };
  },
  mounted() {
    document.querySelector("#map").src = this.encodedUrlAddress();
    document.querySelector("#img-deliverability").src =
      this.deliverabilityIcon();
    document.querySelector("#img-primary").src = this.primaryMessageIcon();
    document.querySelector("#img-secondary").src = this.secondaryUnitIcon();
    document.querySelector("#img-zip").src = this.zipIcons();
  },
  methods: {
    deliverabilityMessage() {
      if (
        [
          "deliverable",
          "deliverable_missing_unit",
          "deliverable_incorrect_unit",
          "deliverable_unnecessary_unit",
        ].includes(this.$store.state.game.avRes.deliverability)
      ) {
        return "Street address matched city, state, zip";
      } else {
        return "Address does not match ZIP-4";
      }
    },
    deliverabilityIcon() {
      if (
        [
          "deliverable",
          "deliverable_missing_unit",
          "deliverable_incorrect_unit",
          "deliverable_unnecessary_unit",
        ].includes(this.$store.state.game.avRes.deliverability)
      ) {
        return "https://assets-global.website-files.com/5e1e5c62fa3d4447af417098/5fb4c743b6a1493fca6c8f78_checkmark.svg";
      } else {
        return "https://assets-global.website-files.com/5e1e5c62fa3d4447af417098/5fb4db40e74d603f2a7c3bc2_error.svg";
      }
    },
    primaryMessage() {
      if (!/\d/.test(this.$store.state.game.avRes.components.primary_number)) {
        return "Missing primary number";
      } else {
        return;
      }
    },
    primaryMessageIcon() {
      if (!/\d/.test(this.$store.state.game.avRes.components.primary_number)) {
        return "https://assets-global.website-files.com/5e1e5c62fa3d4447af417098/5fb4db40e74d603f2a7c3bc2_error.svg";
      }
    },
    secondaryUnitMessage() {
      if (
        this.$store.state.game.avRes.deliverability ==
        "deliverable_missing_unit"
      ) {
        return "Matched primary address, but missing secondary number";
      } else if (this.$store.state.game.avRes.deliverability == "deliverable") {
        return "Confirmed all address components";
      } else if (
        this.$store.state.game.avRes.deliverability ==
        "deliverable_unnecessary_unit"
      ) {
        return "Updated address components";
      } else {
        return;
      }
    },
    secondaryUnitIcon() {
      if (
        this.$store.state.game.avRes.deliverability ==
        "deliverable_missing_unit"
      ) {
        return "https://assets-global.website-files.com/5e1e5c62fa3d4447af417098/5fb4db5dc4302d28a6b56a87_exclamation.svg";
      } else if (this.$store.state.game.avRes.deliverability == "deliverable") {
        return "https://assets-global.website-files.com/5e1e5c62fa3d4447af417098/5fb4c743b6a1493fca6c8f78_checkmark.svg";
      } else if (
        this.$store.state.game.avRes.deliverability ==
        "deliverable_unnecessary_unit"
      ) {
        return "https://assets-global.website-files.com/5e1e5c62fa3d4447af417098/5fb4db5dc4302d28a6b56a87_exclamation.svg";
      }
    },
    // "https://assets-global.website-files.com/5e1e5c62fa3d4447af417098/5fb4db5dc4302d28a6b56a87_exclamation.svg"
    compareZips() {
      const inputZip = this.inputFunc().split(" ").pop();
      if (inputZip !== this.$store.state.game.avRes.components.zip_code) {
        return "Fixed zip code";
      } else {
        return;
      }
    },
    zipIcons() {
      const inputZip = this.inputFunc().split(" ").pop();
      if (inputZip !== this.$store.state.game.avRes.components.zip_code) {
        return "https://assets-global.website-files.com/5e1e5c62fa3d4447af417098/5fb4db5dc4302d28a6b56a87_exclamation.svg";
      }
    },
    encodedUrlAddress() {
      return (
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyAU7kc9Qf7jSDKOV5aOF9AlFyYbaXt22SQ&q=" +
        encodeURIComponent(this.$store.getters["game/getCurrAnswer"]())
      );
    },
    currAns() {
      return this.$store.getters["game/getCurrAnswer"]();
    },
    inputFunc() {
      return this.$store.state.game.input.toUpperCase();
    },
    nextPage() {
      const { questionInd, numQuestions } = this.$store.state.game;

      if (questionInd === numQuestions - 1) {
        this.$emit("set-page", "END");
        return;
      } else {
        this.$store.commit({
          type: "game/setMatch",
          val: false,
        });
        this.$store.commit({ type: "game/incrementQuestion" });
        // replace this with map screen
        this.$emit("set-page", "QUESTION");
      }
    },
  },
};
</script>

<style>
.label-text {
  font-size: 14px;
  margin-left: 20px;
}

.label-padded-top {
  margin-top: 1.5em;
}

#input-address {
  font-weight: bold;
}

.address-text {
  font-size: 16px;
  margin-left: 20px;
}

.wrapper-lob-info-box {
  background: #f0f4f4;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  border-radius: 0.7em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.lob-stats {
  font-size: 16px;
  margin-left: 20px;
  display: inline-block;
  margin-right: 20px;
}

.lob-info-box {
  font-size: 15px;
  display: flex;
  margin-left: 3.5em;
}

.address-text #input-address {
  margin-top: 20px;
  margin-bottom: 10px;
}

.stat {
  color: #2596be;
  float: right;
  margin-right: 30px;
  margin-left: 20px;
}

.img-indicators {
  height: 18px;
  width: fit-content;
  vertical-align: text-bottom;
  margin-top: 0.17em;
}

.next-button-map {
  background: #0154ac;
  color: white;
  padding: 3% 10%;
  font-family: Larsseit;
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  line-height: 20px;
  border-radius: 10px;
  margin: 2em auto;
}
@media only screen and (min-width: 640px) {
  .next-button-map {
    width: 100%;
  }
}
</style>
