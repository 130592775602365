<template>
  <div class="my-container">
    <logout-btn />
    <div
      style="
        width: 100%;
        height: 80vh;
        justify-content: space-evenly;
        align-items: center;
        display: flex;
        flex-direction: column;
      "
    >
      <P class="text-5xl text-primary-300" style="margin-top: 5rem">SUCCESS</P>
      <lob-button @click="this.$router.push('/events')">
        EDIT MORE QUESTIONS
      </lob-button>
    </div>
  </div>
</template>

<script>
import LogoutBtn from "./LogoutBtn.vue";

export default {
  name: "SuccessPage",
  components: {
    LogoutBtn,
  },
};
</script>
