<template>
  <div class="my-container">
    <logout-btn />
    <Modal :visible="existingCityIds.length > 0" @close="existingCityIds = []">
      <template v-slot:header>
        <h4>Delete Existing Cities</h4>
      </template>
      <P>
        The city you'd like to add already has a set of questions for it in the
        database. Are you sure you'd like to replace them with this new set of
        questions?
      </P>
      <template v-slot:footer>
        <div class="my-modal-footer">
          <lob-button variant="secondary" @click="existingCityIds = []">
            No
          </lob-button>
          <lob-button @click="deleteExistingCitiesAndContinue">Yes</lob-button>
        </div>
      </template>
    </Modal>

    <H1 class="text-primary-gray-500">CONFIRMATION</H1>
    <H2 class="text-primary-300">Landmarks</H2>

    <landmark
      v-for="(landmarkInd, ind) in landmarksSetAsArr"
      :key="landmarkInd"
      :landmarkInd="landmarkInd"
      :ind="ind"
    />
    <div
      class="powered-by-google"
      style="padding-top: 1rem; padding-bottom: 3rem"
    >
      <img :src="require('@/assets/powered_by_google_on_white.png')" />
    </div>
    <H2 class="text-primary-300">Number of Questions Per User</H2>
    <P
      class="text-5xl text-primary-300 centered-column"
      style="padding-bottom: 2rem"
    >
      {{ this.$store.state.admin.numQuestions }}
    </P>
    <lob-button @click="this.$router.push('/questions')" class="back-btn">
      BACK
    </lob-button>
    <lob-button @click="submit" class="next-btn"> SUBMIT </lob-button>
  </div>
</template>

<script>
import LogoutBtn from "./LogoutBtn.vue";
import Landmark from "./Landmark.vue";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  query,
  deleteDoc,
  doc,
  where,
  getDocs,
} from "firebase/firestore";

export default {
  name: "ConfirmPage",
  components: {
    LogoutBtn,
    Landmark,
  },
  data() {
    return {
      existingCityIds: [],
    };
  },
  methods: {
    async getExistingCities() {
      const { cityState } = this.$store.state.admin;

      // returns questions that already exist for this landmark in our db
      const q = query(collection(db, "cities"), where("city", "==", cityState));

      const querySnapshot = await getDocs(q);
      if (querySnapshot.size) {
        let existingCityIds = [];
        // don't expect this array to ever exceed length of 1
        // but maybe developer will manually mess with the db and lead
        // to dups of certain city
        querySnapshot.forEach((doc) => existingCityIds.push(doc.id));
        this.existingCityIds = existingCityIds;
      }
    },
    deleteExistingCitiesAndContinue() {
      this.existingCityIds.forEach((id) => {
        deleteDoc(doc(db, "cities", id))
          .then(() => console.log("Successfully deleted city"))
          .catch((err) => console.error(err));
      });

      this.addToDb();
    },
    async addToDb() {
      const { eventName, cityState, startDate, endDate, numQuestions } =
        this.$store.state.admin;

      // since Firebase can't handle nested Objects, need to flatten selectedAvResults
      const { flatAv, comps, del_analysis, lcs } = this.selectedAvResults;

      try {
        // add landmarks to DB
        const docRef = await addDoc(collection(db, "cities"), {
          city: cityState,
          landmarks: this.landmarkNames,
          photos: this.selectedPhotos,
          numQuestions: numQuestions,
          answers: this.selectedAnswers,
          avResultsFlat: flatAv,
          avComps: comps,
          avDelAnalysis: del_analysis,
          avLCS: lcs,
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding cities document: ", e);
        // TODO: send notif to our team and somehow signal to user that things have gone wrong
        return;
      }

      try {
        // add event to DB
        const docRef = await addDoc(collection(db, "events"), {
          name: eventName,
          city: cityState,
          startDate: startDate,
          endDate: endDate,
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding events document: ", e);
      }

      this.$router.push("/success");
    },
    async submit() {
      await this.getExistingCities();
      if (!this.existingCityIds.length) this.addToDb();
    },
  },
  computed: {
    landmarksSetAsArr() {
      return Array.from(this.$store.state.admin.selectedLandmarks);
    },
    landmarkNames() {
      const { selectedLandmarks, landmarks } = this.$store.state.admin;
      const res = [];
      selectedLandmarks.forEach((landmarkInd) => {
        res.push(landmarks[landmarkInd].name);
      });
      return res;
    },
    numSelectedLandmarks() {
      return this.$store.state.admin.selectedLandmarks.size;
    },
    selectedPhotos() {
      const copy = {};
      const {
        photos, // all photos for a given landmark, for every landmark in the city
        selectedPhotos, // just the photos for a given landmark, for every landmark in the city
        selectedLandmarks, // indices of selected landmarks
      } = this.$store.state.admin;
      let ind = 0;
      for (const landmarkInd of selectedLandmarks) {
        const entry = [];
        selectedPhotos[landmarkInd].forEach((photoInd) => {
          const { url, html_attributions } = photos[landmarkInd][photoInd];

          entry.push({
            ref: url.slice(70, url.indexOf("&")), // photoReference which uniquely identifies this photo
            attrs: html_attributions,
          });
        });
        copy[ind] = entry;
        ind++;
      }
      return copy;
    },
    selectedAnswers() {
      const copy = [];
      const { answers, selectedLandmarks } = this.$store.state.admin;

      for (const landmarkInd of selectedLandmarks)
        copy.push(answers[landmarkInd]);
      return copy;
    },
    selectedAvResults() {
      const flatAv = [],
        comps = [],
        del_analysis = [],
        lcs = [];
      const { avResults, selectedLandmarks } = this.$store.state.admin;

      for (const landmarkInd of selectedLandmarks) {
        const oneRes = avResults[landmarkInd];
        comps.push(oneRes.components);
        del_analysis.push(oneRes.deliverability_analysis);
        lcs.push(oneRes.lob_confidence_score);
        delete oneRes.components;
        delete oneRes.deliverability_analysis;
        delete oneRes.lob_confidence_score;
        flatAv.push(oneRes);
      }
      return { flatAv, comps, del_analysis, lcs };
    },
  },
};
</script>
