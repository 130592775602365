<template>
  <logout-btn />
  <div class="my-container">
    <alert v-show="exists || empty" variant="warning">
      <p v-show="exists">This event already exists.</p>
      <p v-show="empty">You didn't input an event.</p>
    </alert>
    <alert v-show="validCity === false" variant="warning">
      <p>This city is not valid.</p>
    </alert>
    <P>
      As you type out your Event's name, the app checks whether an Event by the
      same name already exists. You are disallowed from creating another event
      with an un-unique name since Events are selected solely by their names on
      the Home page.
    </P>
    <text-input
      id="event"
      label="Event Name"
      required
      @input="showExistingEvents"
    />
    <existing-events
      v-if="eventExists && !inProgressEmpty"
      :events="existingEvents"
      :updatable="false"
      @deleted-event="updateEvents"
    />

    <P>
      Known bug: You need to click a city from the dropdown; selecting via your
      keyboard will cause the app to say this city is not valid and forbid you
      from moving forward.
    </P>
    <label class="block mb-2 text-sm text-gray-500">
      City, state
      <span class="text-sm text-turquoise-900"> * </span>
    </label>
    <div
      class="flex items-center rounded border border-gray-100 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-100 focus-within:border-transparent hover:shadow"
    >
      <vue-google-autocomplete
        id="map"
        ref="address"
        classname="rounded pl-2 pt-3 pb-3 leading-5 w-full text-gray-500 placeholder-gray-100 outline-none !pl-4"
        placeholder=""
        types="(cities)"
        country="us"
        @blur="onBlur"
        @inputChange="cityChange"
      />
    </div>
    <div class="powered-by-google" style="padding-top: 1rem">
      <img :src="require('@/assets/powered_by_google_on_white.png')" />
    </div>
    <existing-city v-if="cityExists && cityState.length" @assign="assignCity" />

    <date-input
      @click.stop="startOpen = !startOpen"
      :value="startDate"
      id="start-date"
      v-model="startDate"
      v-model:open="startOpen"
      label="Start Date *"
    />

    <date-input
      @click.stop="endOpen = !endOpen"
      :value="endDate"
      id="end-date"
      v-model="endDate"
      v-model:open="endOpen"
      label="End Date *"
      style="padding-bottom: 2rem"
    />
    <lob-button @click="this.$router.push('/events')" class="back-btn">
      BACK
    </lob-button>
    <lob-button @click="next" class="next-btn"> NEXT </lob-button>
  </div>
</template>

<script>
import LogoutBtn from "./LogoutBtn.vue";
import { db } from "../../firebase";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import ExistingCity from "./ExistingCity.vue";
import ExistingEvents from "./ExistingEvents.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "CreateEventPage",
  data() {
    const initDate = new Date();
    initDate.setHours(0, 0, 0, 0);

    return {
      eventName: "",
      cityState: "",
      eventExists: false,
      cityExists: false,
      exists: false, // diff from eventExists since this only changes on clicking "NEXT"
      empty: false, // for err banner
      inProgressEmpty: true, // to stop "" from returning all events
      startDate: initDate,
      endDate: initDate,
      startOpen: false,
      endOpen: false,
      existingEvents: [],
      allExistingEventNames: new Set(),

      validCity: null,
      address: "",
    };
  },
  components: {
    LogoutBtn,
    ExistingCity,
    ExistingEvents,
    VueGoogleAutocomplete,
  },
  async mounted() {
    const q = query(collection(db, "events"));

    const querySnapshot = await getDocs(q);
    const tmp = new Set();
    querySnapshot.forEach((doc) => tmp.add(doc.data().name));
    this.allExistingEventNames = tmp;
  },
  methods: {
    updateEvents(ind, eventName) {
      this.allExistingEventNames.delete(eventName);
      this.existingEvents.splice(ind, 1);
      this.eventExists = false;
    },
    async assignCity() {
      // for when city already exists and just want to assign this city's questions to this new event

      if (this.validate()) return;

      try {
        // add event to DB
        const docRef = await addDoc(collection(db, "events"), {
          name: this.eventName,
          city: this.cityState,
          startDate: this.startDate,
          endDate: this.endDate,
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding events document: ", e);
      }

      this.$router.push("/success");
    },
    async determineExistingCity() {
      const q = query(
        collection(db, "cities"),
        where("city", "==", this.cityState)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.size) {
        this.cityExists = true;
      } else this.cityExists = false;
    },
    onBlur() {
      const firstRes = document.querySelector("div.pac-item");
      if (firstRes) {
        const result = document.querySelectorAll("div.pac-container");
        const lastContainer = result[result.length - 1]; // the actual suggestions div
        const city = lastContainer.firstChild.children[1].innerText;
        const state = lastContainer.firstChild.children[2].innerText;

        this.$refs.address.update(`${city}, ${state}`);
        this.cityState = `${city}, ${state}`;
        this.validCity = true;

        this.determineExistingCity();
      } else {
        this.validCity = false;
      }
    },
    cityChange(val) {
      this.cityState = val.newVal;
      this.determineExistingCity();
    },
    async showExistingEvents(eventName) {
      this.eventName = eventName;

      if (this.allExistingEventNames.has(this.eventName)) {
        this.eventExists = true;

        const q = query(
          collection(db, "events"),
          where("name", "==", this.eventName)
        );
        const querySnapshot = await getDocs(q);
        const events = [];
        querySnapshot.forEach((doc) => events.push([doc.id, doc.data()]));
        this.existingEvents = events;
      } else this.eventExists = false;

      if (this.eventName === "") this.inProgressEmpty = true;
      else this.inProgressEmpty = false;
    },
    async next() {
      if (this.validate()) return;

      /*
        save eventName, cityState, startDate, and endDate to global
        store then save those to Firestore on Confirm page's Submit
      */
      this.$store.commit({
        type: "admin/setNewEvent",
        eventName: this.eventName,
        cityState: this.cityState,
        startDate: this.startDate,
        endDate: this.endDate,
      });
      // tells next page to do API calls to get landmarks in this city
      this.$store.commit({ type: "admin/setLandmarks" });
      this.$store.commit({ type: "admin/setPhotosAnswersAvResults" });
      this.$store.commit({ type: "admin/setSelectedLandmarks" });
      this.$store.commit({ type: "admin/resetSelectedPhotos" });
      this.$store.commit({ type: "admin/setNumQuestions" });
      this.$router.push("/questions");
    },
    validate() {
      let someErr = false;

      // validate event name for err banner
      if (this.eventExists) {
        this.exists = true;
        this.empty = false;
        someErr = true;
      } else {
        this.exists = false;
        if (this.eventName === "") {
          // non-empty string
          this.empty = true;
          someErr = true;
        } else {
          this.empty = false;
        }
      }

      if (!this.validCity) someErr = true;

      return someErr;
    },
  },
};
</script>
