<template>
  <alert v-show="deleteCityErr" variant="error">
    You must delete events using this city before you may delete this city.
  </alert>

  <P>
    These are all existing Cities (i.e., question sets with photos already
    selected for landmarks in each city) in the database. They will be handy if
    Lob has multiple events in the same city. In such a case, you'd go to the
    next page and type in your desired city in the autocomplete field, as per
    usual. Then the app will sense that a question set for this city already
    exists in our database and ask whether you'd like to assign this question
    set to your new event.
  </P>
  <P>
    If you'd rather delete an existing question set corresponding to some city,
    you can either delete that City here or continue past the next page. With
    the latter choice, you'll find that the old question set will be replaced by
    your newly selected one after you've gone beyond the Confirm page.
  </P>
  <P>
    As of now, there is no way to see the photos associated with each existing
    City or allow an admin to update the photos associated with an existing
    City.
  </P>
  <lob-table space="md" class="min-w-full divide-y divide-gray-200">
    <TableHeader>
      <div>Name</div>
      <div />
    </TableHeader>
    <TableBody>
      <TableRow v-for="(city, ind) in cities" :key="ind">
        <div>{{ city[1].city }}</div>
        <lob-button @click="validateDeletion(ind)"> DELETE </lob-button>
      </TableRow>
    </TableBody>
  </lob-table>

  <Modal :visible="deleteInd >= 0" @close="deleteInd = -1">
    <template v-slot:header>
      <h4>Delete City</h4>
    </template>

    Are you sure you'd like to delete all questions and landmarks associated
    with {{ deleteInd >= 0 && cities[deleteInd][1]["city"] }}?

    <template v-slot:footer>
      <div class="my-modal-footer">
        <lob-button @click="deleteInd = -1" variant="secondary"
          >Cancel</lob-button
        >
        <lob-button @click="deleteCity">Confirm</lob-button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { db } from "../../firebase";
import { deleteDoc, doc } from "firebase/firestore";

export default {
  name: "ExistingCities",
  emits: ["deleted-city"],
  data() {
    return {
      deleteCityErr: false,
      deleteInd: -1,
    };
  },
  props: {
    cities: {
      type: Array,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
  },
  methods: {
    validateDeletion(ind) {
      const eventsUsingThisCity = this.events.filter(
        (e) => e[1].city == this.cities[ind][1]["city"]
      );
      if (eventsUsingThisCity.length) {
        this.deleteCityErr = true;
        return;
      } else this.deleteCityErr = false;

      this.deleteInd = ind; // toggle confirmation modal open
    },
    deleteCity() {
      deleteDoc(doc(db, "cities", this.cities[this.deleteInd][0]))
        .then(() => {
          this.$emit("deleted-city", this.deleteInd);
          this.deleteInd = -1;
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>
