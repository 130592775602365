<template>
  <div class="backdrop-wave">
    <!-- light wave -->
    <svg
      class="wave hidden-for-desktop"
      viewBox="0 0 428 509"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M533.109 761L-42 761L-26.5155 0L197.996 59.182C267.998 77.6347 335.789 103.634 400.178 136.724L560 218.856L533.109 761Z"
        fill="#7CC6DD"
        fill-opacity="0.7"
      />
    </svg>

    <svg
      class="wave hidden-for-mobile"
      width="1512"
      height="666"
      viewBox="0 0 1512 666"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1512 743.882L-4 743.882C-4 743.882 -37 129 -4 175.5C29 222 781.004 0.5 1143 0.5C1389 0.5 1512 23.8824 1512 23.8824L1512 743.882Z"
        fill="#7CC6DD"
        fill-opacity="0.7"
      />
    </svg>

    <svg
      class="wave hidden-for-mobile"
      width="1512"
      height="483"
      viewBox="0 0 1512 483"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1512 692.148L0 692.148L0 53.1482C0 53.1482 247.142 -9.60803 575.5 1.26587C839.243 10 1212.5 138.884 1512 88.2659C1811.5 37.6482 1512 692.148 1512 692.148Z"
        fill="#0154AC"
      />
    </svg>

    <!-- dark wave -->
    <svg
      class="wave hidden-for-desktop"
      viewBox="0 0 428 373"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.71904 633.5L460.957 633.5L493.5 6L450.094 2.9187C267.643 -10.033 85.7299 34.6066 -70 130.544L8.71904 633.5Z"
        fill="#0154AC"
      />
    </svg>
  </div>
</template>

<style>
.wave {
  bottom: 0;
  position: absolute;
}

.backdrop-wave {
  background-color: #f3f5ff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
</style>
