<template>
  <div class="outer-container">
    <div class="bg-upper-container">
      <img class="bg-cloud-1" src="../../assets/cloud.png" alt="Cloud" />
      <img class="bg-cloud-2" src="../../assets/cloud.png" alt="Cloud" />
      <span class="thanks-text">Thanks for playing!</span>
    </div>
    <div class="bg-mid-container">
      <img
        class="bg-hiking-person"
        src="../../assets/hiking-person.png"
        alt="Person with Flag"
      />
      <img
        class="bg-location-marker"
        src="../../assets/location-marker.png"
        alt="Red Location Marker"
      />
      <img
        class="bg-marker-highlight"
        src="../../assets/highlight-icon.png"
        alt="Highlight Icon"
      />
    </div>
    <div class="bg-lower-container">
      <img
        class="thanks-bg-1"
        src="../../assets/thanks-bg-1.svg"
        alt="Light Blue Wave"
      />
      <img
        class="thanks-bg-2"
        src="../../assets/thanks-bg-2.svg"
        alt="Blue Wave"
      />
    </div>
    <lob-button class="thanks-button" @click="returnHome">
      Play Again
    </lob-button>
  </div>
</template>

<script>
import "./Thanks.css";

export default {
  name: "Thanks",
  emits: ["set-page"],
  data() {
    return {};
  },
  methods: {
    returnHome() {
      this.$emit("set-page", "HOME");
      return;
    },
  },
};
</script>
