<template>
  <div
    style="
      position: fixed;
      bottom: 0;
      background-color: white;
      font-family: Larsseit;
      color: #0c63c0;
      display: flex;
      align-items: center;
      padding: 0.05rem 1rem;
      font-size: small;
      width: 100vw;
      justify-content: space-evenly;
    "
    v-if="open"
  >
    <Close
      class="w-4 h-4 text-primary-500"
      style="position: absolute; top: 0.5rem; right: 0.5rem"
      @click="close"
    />
    <P>
      This site uses your browser's local storage to check whether you're a
      unique user for anonymized analytics.
    </P>
    <lob-button
      @click="accept"
      style="height: fit-content; padding: 0.1rem 1rem; border-radius: 0"
    >
      Accept
    </lob-button>
  </div>
</template>
<script>
export default {
  name: "TrackingConsent",
  emits: ["close"],
  data() {
    return {
      open: !window.localStorage.getItem("tt-allow"),
    };
  },
  methods: {
    accept() {
      window.localStorage.setItem("tt-allow", true);
      this.close();
    },
    close() {
      this.open = false;
      this.$emit("close");
    },
  },
};
</script>
