<template>
  <div class="my-container">
    <logout-btn />
    <P>
      Please contact the DevEx team if there's a statistic, graph, or other
      piece of information that you'd like to be added here.
    </P>

    <H1>TOTAL NUMBER OF VISITORS</H1>
    <H2 style="width: 100%; text-align: center">{{ totalVisitors }}</H2>
    <H1>NUMBER OF VISITORS PER EVENT</H1>
    <lob-table space="md" class="min-w-full divide-y divide-gray-200">
      <TableHeader>
        <div>Event Name</div>
        <div>Number of Visitors</div>
      </TableHeader>
      <TableBody>
        <TableRow v-for="(eventStat, ind) in eventStats" :key="ind">
          <div>{{ eventStat[0].slice(0, -13) }}</div>
          <div>{{ eventStat[1] }}</div>
        </TableRow>
      </TableBody>
    </lob-table>
    <lob-button @click="this.$router.push('/events')" class="back-btn">
      BACK
    </lob-button>
  </div>
</template>

<script>
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import LogoutBtn from "./LogoutBtn.vue";

export default {
  name: "EventStatsPage",
  components: {
    LogoutBtn,
  },
  data() {
    return {
      eventStats: [],
      totalVisitors: 0,
    };
  },
  async mounted() {
    const querySnapshot = await getDocs(collection(db, "analytics"));
    const res = [];
    querySnapshot.forEach((doc) => {
      res.push([doc.id, doc.data()]);
    });
    console.log(res);
    res.forEach((entry) => {
      switch (entry[0]) {
        case "anon_nums":
          this.totalVisitors = entry[1].visitors;
          break;
        case "events_stats":
          this.eventStats = Object.entries(entry[1]);
          break;
        default:
          console.error(`Unhandled statistic: ${entry[0]}`);
      }
    });
  },
};
</script>
