<template>
  <div class="tutorial-outer-container">
    <div class="hidden-for-desktop">
      <div class="tutorial3-upper-container">
        <img
          class="tutorial3-cloud-1"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="tutorial3-cloud-2"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="bg-rocket"
          src="../../assets/person-at-computer.png"
          alt="Person at Computer"
        />
      </div>
      <div class="bg-tutorial3-lower-container">
        <span class="tutorial-bottom-heading">How Do Points Work?</span>
        <span class="tutorial-bottom-text"
          >You can earn a maximum of <b>5</b> points per question. When you
          reach the end of the game, you’ll be able to see how many points you
          earned.
        </span>
        <img
          class="tutorial-bg-1"
          src="../../assets/tutorial-bg-light-blue-3.svg"
          alt="Light Blue Wave"
        />
        <img
          class="tutorial-bg-2 bg-tutorial3-wave"
          src="../../assets/tutorial-bg-dark-blue-3.svg"
          alt="Blue Wave"
        />
      </div>
    </div>
    <div class="hidden-for-mobile">
      <div class="tutorial3-upper-container">
        <img class="lob-logo" src="../../assets/lob-logo.png" alt="Lob Logo" />
        <img
          class="tutorial3-cloud-1"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="tutorial3-cloud-2"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="tutorial3-cloud-3"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="bg-computer"
          src="../../assets/person-at-computer.png"
          alt="Person at Computer"
        />
        <span class="tutorial3-text">How Do Points Work?</span>
      </div>
      <div class="bg-tutorial-lower-container">
        <span class="tutorial-bottom-heading"
          >Earn up to 5 Points per Question</span
        >
        <span class="tutorial-bottom-text tut3"
          >For each equation, you can earn a maximum of <b>5</b> points per
          question. When you reach the end of the game, you’ll be able to see
          how many points you earned.
        </span>
        <img
          class="tutorial-bg-1"
          src="../../assets/desktop-tutorial-bg-light-blue-3.svg"
          alt="Light Blue Wave"
        />
        <img
          class="tutorial-bg-2"
          src="../../assets/desktop-tutorial-bg-dark-blue-1.svg"
          alt="Blue Wave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./Tutorial3.css";

export default {
  name: "Tutorial3",
  emits: ["set-page"],
};
</script>
