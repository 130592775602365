const gameModule = {
  state() {
    return {
      event: null,
      answers: null,
      avResults: null,
      landmarks: null,
      numQuestions: null,
      photos: null,
      questionInd: 0,
      attemptsLeft: 0,
      points: 0,
      currPoints: 0,
      match: false,
      input: null,
      avRes: null,
      photoInd: 0, // for keeping track of how many hints user has used
    };
  },
  getters: {
    getCurrAnswer: (state) => () => {
      const { questionInd, answers } = state;
      return answers[questionInd];
    },
    getCurrAvRes: (state) => () => {
      const { questionInd, avResults } = state;
      return avResults[questionInd];
    },
    getCurrLandmark: (state) => () => {
      const { questionInd, landmarks } = state;
      return landmarks[questionInd];
    },
  },
  mutations: {
    setPhotoInd(state, payload) {
      state.photoInd = payload.photoInd;
    },
    setInputtedAnswer(state, payload) {
      // set both inputted answer and AV res
      state.input = payload.input;
      state.avRes = payload.avRes;
    },
    incrementQuestion(state) {
      state.questionInd++;
    },
    decrementAttempts(state) {
      state.attemptsLeft--;
    },
    restoreAttemptsHints(state) {
      state.attemptsLeft = 3;
      state.photoInd = 0;
    },
    addPoints(state, newPoints) {
      state.points = state.points + newPoints.newPoints;
    },
    setMatch(state, val) {
      state.match = val;
    },
    setCurrPoints(state, earned) {
      state.currPoints = earned.earned;
    },
    setQuestions(state, payload) {
      state.questionInd = 0;
      state.attemptsLeft = payload.attemptsLeft;
      state.points = payload.points;
      state.currPoints = payload.currPoints;
      state.answers = payload.answers;
      state.avResults = payload.avResults;
      state.landmarks = payload.landmarks;
      state.photos = payload.photos;
      state.numQuestions = payload.numQuestions;
      state.event = payload.event;
    },
  },
  namespaced: true,
};

export default gameModule;
