<template>
  <div class="question-container">
    <P class="points-container hidden">
      {{ this.$store.state.game.points }}
      <img :src="require('@/assets/coin.png')" />
    </P>
    <alert v-show="verErr" variant="warning">
      <p>{{ verErr }}</p>
    </alert>

    <swiper
      class="hint-container"
      :modules="modules"
      :slides-per-view="1"
      :space-between="30"
      :pagination="{ clickable: true }"
      @afterInit="setSwiper"
    >
      <swiper-slide v-for="(photo, ind) in photos" :key="ind">
        <div
          class="hint-photo"
          v-bind:style="{ 'background-image': 'url(' + photo + ')' }"
          @click="enlargeImg(ind)"
        />
      </swiper-slide>
    </swiper>

    <div class="powered-by-google hidden">
      <img
        :src="require('@/assets/powered_by_google_on_white.png')"
        style="padding-bottom: 0.5rem"
      />

      Due to the nature of this game, we will show the html attributions for
      these photos' sources on the next page (after you've answered).
    </div>

    <Modal :visible="enlargedInd >= 0" @close="enlargedInd = -1">
      <div
        v-bind:style="{
          width: '80vw',
          height: '80vh',
          'background-image': 'url(' + photos[enlargedInd] + ')',
          'background-color': '#CCC',
          'background-position': 'center',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
        }"
        @click="enlargeImg(ind)"
      />
    </Modal>

    <div class="backdrop hidden" @click="closeInput" />

    <div class="wrapper-guess state-collapsed-for-mobile on-top">
      <div class="inner">
        <h2 class="text-centered hidden-when-collapsed">Where am I?</h2>
        <p class="text-centered hidden-when-collapsed">
          Type in your best guess below!
        </p>
        <div class="wrapper-guess-input hidden-when-collapsed">
          <input
            id="primary_line"
            type="text"
            placeholder="Primary Line"
            v-model="primaryLine"
          />
          <input
            id="city"
            type="text"
            placeholder="City"
            required
            v-model="city"
          />
          <input
            id="state"
            type="text"
            placeholder="State"
            required
            v-model="state"
          />
          <input
            id="zip"
            type="text"
            placeholder="Zip Code"
            required
            v-model="zip"
          />
        </div>

        <p class="text-centered text-padded" :hidden="remainingAttempts !== 3">
          You have <b>3</b> chances to guess the correct address.
        </p>
        <p class="text-centered text-padded" :hidden="remainingAttempts !== 2">
          You have <b>2</b> attempts left.
        </p>
        <p
          class="text-centered text-padded text-red"
          :hidden="remainingAttempts !== 1"
        >
          You only have <b>1</b> attempt left!
        </p>
        <p class="text-centered hidden">Here's an image to help you guess!</p>
        <p class="text-centered hidden">
          Here's a 3rd image to help you guess!
        </p>

        <div class="wrapper-button-bank">
          <div class="flex-spacer" />
          <lob-button
            class="button-hint"
            @click="addPhoto"
            :disabled="this.$store.state.game.photoInd === 2"
          >
            Hint
            <svg
              width="16"
              height="23"
              class="icon-hint"
              viewBox="0 0 16 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5 21.3379H11.5"
                stroke="#0154AC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.5183 12.244L14.2866 12.8841L13.5183 12.244ZM2.48171 12.244L1.71341 12.8841H1.71341L2.48171 12.244ZM4.5 16.4171H5.5H4.5ZM2 8.20276C2 5.18699 4.62527 2.63519 8 2.63519V0.635193C3.64275 0.635193 0 3.9642 0 8.20276H2ZM8 2.63519C11.3747 2.63519 14 5.18699 14 8.20276H16C16 3.9642 12.3573 0.635193 8 0.635193V2.63519ZM14 8.20276C14 9.47835 13.5383 10.6577 12.75 11.6039L14.2866 12.8841C15.3551 11.6015 16 9.97526 16 8.20276H14ZM3.25001 11.6039C2.46172 10.6577 2 9.47835 2 8.20276H0C0 9.97526 0.644875 11.6015 1.71341 12.8841L3.25001 11.6039ZM10.3333 17.0543H5.66667V19.0543H10.3333V17.0543ZM5.66667 17.0543C5.60014 17.0543 5.55273 17.0296 5.52593 17.0045C5.50023 16.9803 5.5 16.9644 5.5 16.9597H3.5C3.5 18.1757 4.53107 19.0543 5.66667 19.0543V17.0543ZM10.5 16.9597C10.5 16.9644 10.4998 16.9803 10.4741 17.0045C10.4473 17.0296 10.3999 17.0543 10.3333 17.0543V19.0543C11.4689 19.0543 12.5 18.1757 12.5 16.9597H10.5ZM5.5 16.9597V16.4171H3.5V16.9597H5.5ZM10.5 16.4171V16.9597H12.5V16.4171H10.5ZM1.71341 12.8841C2.75286 14.1317 3.5 15.2557 3.5 16.4171L5.5 16.4171C5.5 14.4527 4.26236 12.819 3.25001 11.6039L1.71341 12.8841ZM12.75 11.6039C11.7376 12.819 10.5 14.4527 10.5 16.4171H12.5C12.5 15.2557 13.2471 14.1317 14.2866 12.8841L12.75 11.6039Z"
                fill="#0154AC"
                stroke-width="1"
              />
            </svg>
          </lob-button>

          <lob-button
            class="hidden-when-expanded hidden-for-desktop"
            @click="openInput"
          >
            Guess
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 12.946V17.8108C17 18.3482 16.5523 18.7838 16 18.7838H2C1.44772 18.7838 1 18.3482 1 17.8108V4.18922C1 3.65186 1.44772 3.21625 2 3.21625H7"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 12.9459H10L19 4.18918L16 1.27026L7 10.027V12.9459Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </lob-button>

          <lob-button class="hidden-when-collapsed" @click="checkAnswer">
            Verify
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.60051 10.9852C8.20999 11.3758 8.20999 12.0089 8.60051 12.3995C8.99103 12.79 9.6242 12.79 10.0147 12.3995L8.60051 10.9852ZM14.8609 7.5533C15.2514 7.16277 15.2514 6.52961 14.8609 6.13908C14.4704 5.74856 13.8372 5.74856 13.4467 6.13908L14.8609 7.5533ZM10.0147 12.3995L14.8609 7.5533L13.4467 6.13908L8.60051 10.9852L10.0147 12.3995Z"
                fill="white"
              />
              <path
                d="M12.7302 18.7675L11.8357 19.2148L12.7302 18.7675ZM14.5804 18.6144L13.6246 18.3203L14.5804 18.6144ZM8.93283 11.6201L8.48561 12.5145L8.93283 11.6201ZM9.38004 12.0673L10.2745 11.6201L9.38004 12.0673ZM2.38572 6.41978L2.67981 7.37556L2.38572 6.41978ZM2.2326 8.26998L1.78538 9.16441H1.78538L2.2326 8.26998ZM18.1948 1.55543L18.4889 2.51121L18.1948 1.55543ZM19.4447 2.8053L20.4005 3.09938L19.4447 2.8053ZM17.9008 0.599653L2.09164 5.464L2.67981 7.37556L18.4889 2.51121L17.9008 0.599653ZM1.78538 9.16441L8.48561 12.5145L9.38004 10.7257L2.67981 7.37555L1.78538 9.16441ZM8.48561 12.5145L11.8357 19.2148L13.6246 18.3203L10.2745 11.6201L8.48561 12.5145ZM15.5361 18.9085L20.4005 3.09938L18.4889 2.51121L13.6246 18.3203L15.5361 18.9085ZM11.8357 19.2148C12.6426 20.8286 15.0055 20.633 15.5361 18.9085L13.6246 18.3203L11.8357 19.2148ZM8.48561 12.5145V12.5145L10.2745 11.6201C10.0809 11.233 9.76709 10.9192 9.38004 10.7257L8.48561 12.5145ZM2.09164 5.464C0.367103 5.99462 0.171553 8.35749 1.78538 9.16441L2.67981 7.37555L2.67981 7.37556L2.09164 5.464ZM18.4889 2.51121L18.4889 2.51121L20.4005 3.09938C20.8727 1.56483 19.4353 0.127485 17.9008 0.599653L18.4889 2.51121Z"
                fill="white"
              />
            </svg>
          </lob-button>

          <div class="flex-spacer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";

import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

import "./Question.css";

const Lob = require("lob")(process.env.VUE_APP_LOB_API_KEY);

export default {
  name: "Question",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  emits: ["set-page"],
  data() {
    return {
      primaryLine: "",
      city: "",
      state: "",
      zip: "",

      verErr: false,
      answer: this.$store.getters["game/getCurrAnswer"](),
      photos: [],
      photoInd: this.$store.state.game.photoInd,
      enlargedInd: -1,
      remainingAttempts: this.$store.state.game.attemptsLeft,
      swiper: null,
    };
  },
  mounted() {
    const photoInd = this.$store.state.game.photoInd;
    for (let i = 0; i <= photoInd; i++) {
      this.getPhoto(i);
    }
    window.a = this.$refs;
  },
  methods: {
    enlargeImg(ind) {
      this.enlargedInd = ind;
    },

    addPhoto() {
      this.photoInd = this.$store.state.game.photoInd + 1;
      this.$store.commit({
        type: "game/setPhotoInd",
        photoInd: this.photoInd,
      });
      this.getPhoto(this.photoInd);
    },

    getPhoto(photoInd) {
      const { photos, questionInd } = this.$store.state.game;
      const photoRef = photos[questionInd][photoInd]["ref"];

      fetch(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5000"
            : "https://us-central1-expo-game-89228.cloudfunctions.net/app"
        }/test/getPhoto`,
        {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          credentials: "same-origin",
          body: JSON.stringify({ photoRef }),
        }
      )
        .then((res) => res.text())
        .then((data) => {
          this.photos.push(data);

          // On adding the new photo, swiper's content has not been updated yet.
          // We delay by 1 ms to allow the current cycle to complete
          setTimeout(() => {
            this.swiper.slideTo(this.photos.length, 300, true);
          }, 1);
        })
        .catch((err) => console.log(err));
    },

    compareAnswers(res) {
      if (res) {
        let usersAnswer = "";
        if (!res.primary_line.length) usersAnswer = res.last_line;
        else {
          usersAnswer = `${res.primary_line}`; // can I do `<malicious code>?
          if (res.secondary_line.length)
            usersAnswer += ` ${res.secondary_line}`;
          usersAnswer += `, ${res.last_line}`;
        }

        if (usersAnswer === this.answer) return true;
        return false;
      }
    },

    async checkAnswer() {
      const input = `${this.primaryLine}, ${this.city}, ${this.state}, ${this.zip}`;
      Lob.usVerifications.verify(
        {
          address: input,
        },
        (err, res) => {
          if (err) {
            this.verErr = err;
          } else {
            this.verErr = false; // this should be null
            const match = this.compareAnswers(res);
            const { attemptsLeft, photoInd } = this.$store.state.game;
            if (match) {
              this.$store.commit({
                type: "game/setMatch",
                val: true,
              });
              this.$store.commit({
                type: "game/addPoints",
                newPoints: 2 - photoInd + attemptsLeft,
              });
              this.$store.commit({
                type: "game/setCurrPoints",
                earned: 2 - photoInd + attemptsLeft,
              });
              this.$emit("set-page", "CORRECTRESULT");
              this.$store.commit({
                type: "game/setInputtedAnswer",
                input: input,
                avRes: res,
              });
            } else {
              this.$emit("set-page", "INCORRECTRESULT");
              this.$store.commit({
                type: "game/setInputtedAnswer",
                input: input,
                avRes: res,
              });
            }
          }
        }
      );
    },

    openInput() {
      const ele = document.querySelector(".wrapper-guess");
      ele.classList.toggle("state-expanded");
      ele.classList.toggle("state-collapsed-for-mobile");
      document.querySelector(".backdrop").classList.toggle("hidden");
    },

    closeInput() {
      const ele = document.querySelector(".wrapper-guess");
      ele.classList.toggle("state-expanded");
      ele.classList.toggle("state-collapsed-for-mobile");
      document.querySelector(".backdrop").classList.toggle("hidden");
    },

    setSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>
