<template>

  <div class="my-container">
    <Modal :visible="viewInd >= 0" @close="viewInd = -1" style="z-index:400">
      <template v-slot:header>
        <h4>View Event URL</h4>
      </template>

      <p>The URL for this game is: <code>{{uniqueURL}}</code></p>
      
      <img src="" ref="qrImage" style="width:200px" />
      <template v-slot:footer>
        <div class="my-modal-footer">
          <lob-button @click="viewInd = -1">Close</lob-button>
        </div>
      </template>
    </Modal>
    <Modal :visible="eventInd >= 0" @close="eventInd = -1" style="z-index:400">
      <lob-table space="md" class="min-w-full divide-y divide-gray-200">
        <TableHeader>
          <div>Name</div>
          <div>Email</div>
          <div>Claimed Prize</div>
        </TableHeader>
        <TableRow v-for="(user, ind) in users" :key="ind">
          <div>{{ user.name }}</div>
          <div>{{ user.email }}</div>
          <div @click="updateClaimed(user.id, ind)">
            <Check
              v-bind:style="{
                visibility: user.claimed ? 'visible' : 'hidden',
                margin: 'auto',
              }"
              class="w-6 h-6 text-mint-900"
            />
          </div>
        </TableRow>
      </lob-table>
    </Modal>
    <Modal :visible="deleteInd >= 0" @close="deleteInd = -1" style="z-index:400">
      <template v-slot:header>
        <h4>Delete event</h4>
      </template>

      Are you sure that:
      <ul class="basic-list">
        <li>
          you want to delete event
          {{ deleteInd >= 0 && this.selectedEvent.data.name }}
        </li>
        <li>no other event is using this entry</li>
      </ul>

      <template v-slot:footer>
        <div class="my-modal-footer">
          <lob-button variant="secondary" @click="deleteInd = -1"
            >Cancel</lob-button
          >
          <lob-button @click="deleteEvent">Confirm</lob-button>
        </div>
      </template>
    </Modal>
    <Modal
      style="z-index:400"
      :visible="updateSuccess || updateErr"
      @close="
        updateSuccess = false;
        updateErr = false;
      "
    >
      {{ updateSuccess ? "Update Successful!" : updateErr }}
    </Modal>
    <div style="display:flex; top:-100px;">      
        <lob-button @click="goBack()">Back</lob-button>        
        <logout-btn />
    </div>
    <div class="text-5xl mt-16 mb-2 font-bold">UPDATE EVENT</div>
    
      <div id="errorBlock" v-if="this.error > 0">
        <alert>{{errorMsg}}</alert>
      </div>
      <div v-if="this.error === 0 && this.selectedEvent != null">
        <text-input
          label="Event Name"
          id="eventName"
          v-model="this.selectedEvent.data.name"
        />
        <dropdown
          id="cities-dropdown"
          v-model="this.selectedEvent.data.city"
          label="Location"
          :options="this.options"
          style="width: fit-content"
        />
         <dropdown
          id="isVisible-dropdown"
          v-model="this.isVisible"
          label="Show this game on the main page?"
          :options="this.yesOrNo"
          style="width: fit-content"
        />
        <div style="display:flex">
          <div style="position:flex; flex:0.5; margin-right:20px">
            <label>
              <input
                label="Start Date"
                :value="this.updatedStartDate"
                ref="input1"
                id="start-date-input"
                style="width: 100%"
              />
            </label>
            <datepicker
              id="start-date"
              v-model="this.updatedStartDate"
              v-model:open="this.origStartDate"
              :boundComponent="$refs.input1"
            />
          </div>
          <div style="position:flex; flex:0.5;">
            <label>
              <input
                label="End Date"
                :value="this.updatedEndDate"
                ref="input1"
                id="start-date-input"
                style="width: 100%"
              />
            </label>
            <datepicker
              id="start-date"
              v-model="this.updatedEndDate"
              v-model:open="this.origEndDate"
              :boundComponent="$refs.input1"
            />
          </div>
        </div>
        <div style="position:relative;top:300px">
          <lob-button @click="updateEvent()"> UPDATE </lob-button>
          <br />
          <hr />
          <lob-button @click="viewEventURL(1)">View URL</lob-button>
          <lob-button @click="openDeleteModal(1)"> DELETE </lob-button>
          <lob-button @click="showUsers(1)">
            SUBMITTED RESPONSES
          </lob-button>
        </div>
      </div>
    </div>
</template>
<script>
  import { db } from "../../firebase";
  import LogoutBtn from "./LogoutBtn.vue";
  import {
    getDocs,
    getDoc,
    doc,
    collection,
    updateDoc,
    query,
    deleteDoc,
    where
  } from "firebase/firestore";
  const crypto = require("crypto");
  import QrCodeWithLogo from "qrcode-with-logos";

  export default {
    name: "EventEntry",
    data() {
      return {
          selectedEvent: null,
          cities: [],
          error: 0,
          errorMsg: "",
          updatedStartDate: new Date(),
          updatedEndDate: new Date(),
          viewInd: -1,
          eventInd: -1,
          deleteInd: -1,
          users: [],
          updateSuccess: false,
          updateErr: false,
          uniqueURL: "",
          yesOrNo: ['YES', 'NO'],
          isVisible: ''
      }
    },
    components: {
      LogoutBtn
    },
    mounted: async function() {
      this.selectedEvent = await this.queryById("events", this.$route.params.eventID)
      this.cities = await this.query("cities")
    },
    methods: {
      async query(coll) {
        const querySnapshot = await getDocs(collection(db, coll));
        const res = [];
        querySnapshot.forEach((doc) => {
          res.push([doc.id, doc.data()]);
        });
        return res;
      },
      async queryById(coll, docId) {
        const docQuery = await doc(db, coll, docId)
        const docRef = await getDoc(docQuery)
        const self = this
        if (docRef.exists()) {
          const docData = docRef.data()
          self.updatedStartDate = new Date(docData.startDate.seconds * 1000)
          self.updatedEndDate = new Date(docData.endDate.seconds * 1000)
          if (!docData.isVisible) {
            self.isVisible = 'NO'
          } else {
            self.isVisible = docData.isVisible
          }
          return {"id": docRef.id, "data": docData}
        } else {
          this.error = 1;
          this.errorMsg = "NO RECORD EXISTS";
          return null
        }
      },
      viewEventURL(ind) {
        this.uniqueURL = window.location.host + "/#/?game=" + this.$route.params.eventID
        let qrcode = new QrCodeWithLogo({
          content: this.uniqueURL,
          width: 480,
          download: true,
          image: this.$refs.qrImage,
          logo: {
            src: "https://s3-us-west-2.amazonaws.com/public.lob.com/dashboard/navbar/lob-logo.svg"
          }
        });
        const self = this
        qrcode.toImage().then(function(){
              self.viewInd = ind
        })

      },
      updateEvent() {
        updateDoc(doc(db, "events", this.$route.params.eventID), {
          name: this.selectedEvent.data.name,
          city: this.selectedEvent.data.city,
          startDate: this.updatedStartDate,
          endDate: this.updatedEndDate,
          isVisible: this.isVisible
        })
          .then(() => {
            this.updateSuccess = true;
            setTimeout(() => (this.updateSuccess = false), 2000);
          })
          .catch((err) => {
            console.log(err)
            this.updateErr = err;
          });
      },
      async showUsers(ind) {
        // only allow admin to say user has claimed prize on Events page

        if (ind >= 0) {
          const q = query(
            collection(db, "users"),
            where("event", "==", this.selectedEvent.data.name)
          );
          const querySnapshot = await getDocs(q);
          const res = [];
          querySnapshot.forEach((doc) => {
            const decipherName = crypto.createDecipheriv(
              process.env.VUE_APP_ENCRYPT_ALGO,
              process.env.VUE_APP_ENCRYPT_KEY,
              process.env.VUE_APP_ENCRYPT_INIT
            );
            const decipherEmail = crypto.createDecipheriv(
              process.env.VUE_APP_ENCRYPT_ALGO,
              process.env.VUE_APP_ENCRYPT_KEY,
              process.env.VUE_APP_ENCRYPT_INIT
            );
            const data = doc.data();
            const decryptedName =
              decipherName.update(data.name, "hex", "utf8") +
              decipherName.final("utf8");

            const decryptedEmail =
              decipherEmail.update(data.email, "hex", "utf8") +
              decipherEmail.final("utf8");

            res.push({
              id: doc.id,
              name: decryptedName,
              email: decryptedEmail,
              claimed: data.claimed,
            });
          });
          this.users = res;
        } else this.users = [];

        this.eventInd = ind; // to signal modal to open
      },
      openDeleteModal(ind) {
        this.deleteInd = ind;
      },
      deleteEvent() {
        deleteDoc(doc(db, "events", this.$route.params.eventID))
          .then(() => {
            this.$emit(
              "deleted-event",
              this.deleteInd,
              this.selectedEvent.data.name
            );
            this.deleteInd = -1;
          })
          .catch((err) => console.error(err));
      },
      goBack() {
        console.log(this.$router)
        this.$router.push("/events");
      }
    },
    computed: {
      options() {
        if (this.cities) return this.cities.map((city) => city[1].city);
        else return [];
      },
      origStartDate() {
        if (this.selectedEvent != null) return new Date(this.selectedEvent.data.startDate.seconds * 1000);
        else return new Date()
      },
      origEndDate() {
        if (this.selectedEvent != null) return this.selectedEvent.data.endDate.toString();
        else return ""
      },
    }
  }
</script>