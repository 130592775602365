<template>
  <div class="tutorial-outer-container">
    <div class="hidden-for-desktop">
      <div class="bg-tutorial1-container">
        <img
          class="bg-tutorial1-cloud-1"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="bg-tutorial1-cloud-2"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <span class="tutorial1-text">Let's Get Started!</span>
        <img
          class="bg-tutorial1-location-marker"
          src="../../assets/location-marker.png"
          alt="Red Location Marker"
        />
      </div>
      <div class="bg-tutorial1-mid-container">
        <img
          class="bg-tutorial1-hiking-person"
          src="../../assets/hiking-person.png"
          alt="Person with Flag"
        />
      </div>
      <div class="bg-tutorial1-lower-container">
        <span class="tutorial-bottom-heading">Guess the Correct Address</span>
        <span class="tutorial-bottom-text"
          >Once the game starts, you will have 3 chances to guess the correct
          address associated with some presented image(s).</span
        >
        <img
          class="tutorial-bg-1"
          src="../../assets/tutorial-bg-light-blue-1.svg"
          alt="Light Blue Wave"
        />
        <img
          class="tutorial-bg-2"
          src="../../assets/tutorial-bg-dark-blue-1.svg"
          alt="Blue Wave"
        />
      </div>
    </div>

    <div class="hidden-for-mobile">
      <div class="bg-tutorial1-container">
        <img
          class="bg-tutorial1-cloud-1"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="bg-tutorial1-cloud-2"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="bg-tutorial1-cloud-3"
          src="../../assets/cloud.png"
          alt="Cloud"
        />

        <img class="lob-logo" src="../../assets/lob-logo.png" alt="Lob Logo" />

        <span class="tutorial1-text">Let's Get Started!</span>
        <img
          class="bg-tutorial1-location-marker"
          src="../../assets/location-marker.png"
          alt="Red Location Marker"
        />
      </div>
      <div class="bg-tutorial1-mid-container">
        <img
          class="bg-tutorial1-hiking-person"
          src="../../assets/hiking-person.png"
          alt="Person with Flag"
        />
      </div>
      <div class="bg-tutorial-lower-container">
        <span class="tutorial-bottom-heading">Guess the Correct Address</span>
        <span class="tutorial-bottom-text"
          >Once the game starts, you will have 3 chances to guess the correct
          address associated with some presented image(s).</span
        >
        <img
          class="tutorial-bg-1"
          src="../../assets/desktop-tutorial-bg-light-blue-1.svg"
          alt="Light Blue Wave"
        />
        <img
          class="tutorial-bg-2"
          src="../../assets/desktop-tutorial-bg-dark-blue-1.svg"
          alt="Blue Wave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./Tutorial1.css";

export default {
  name: "Tutorial1",
  emits: ["set-page"],
};
</script>
