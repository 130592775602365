<template>
  <div
    class="centered-column"
    style="height: 90vh; justify-content: center; width: 100%"
  >
    <div
      style="
        width: 90%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
      "
    >
      <H2>Only DevEx members from hereon!</H2>
      <P
        >If you'd like access for your Lob email, contact someone on the DevEx
        team.</P
      >
      <lob-button @click="googleSignIn" style="margin-bottom: 1.5rem">
        Login with your Lob email
      </lob-button>
      <lob-button @click="this.$router.push('/')">Play Game</lob-button>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "../../firebase";

const ALLOWED_USERS = [
  "achyuta.iyengar@lob.com",
  "benny.kitchell@lob.com",
  "aditi@lob.com",
  "jessica.ho@lob.com",
  "makeda.davis@lob.com",
  "matthew.jackson@lob.com",
  "shariq.torres@lob.com",
  "sid.maestre@lob.com",
  "walker.palecek@lob.com",
];

export default {
  name: "LoginPage",
  methods: {
    googleSignIn() {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });

      signInWithPopup(getAuth(), provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;
          if (!ALLOWED_USERS.includes(user.email)) {
            signOut(getAuth()).catch((signOutErr) => {
              throw new Error(signOutErr);
            });
            throw new Error(
              "Only the Lob accounts of DevEx members have admin access. If you'd like to be added, contact someone on the DevEx team."
            );
          }
          this.$router.push("/events");
        })
        .catch((error) => {
          if (
            error.code !== "auth/popup-closed-by-user" &&
            error.code !== "auth/cancelled-popup-request"
          )
            window.alert(error.message);
        });
    },
  },
};
</script>
