<template>
  <lob-table
    space="md"
    class="min-w-full divide-y divide-gray-200 landmarks-table"
  >
    <TableBody>
      <TableRow v-for="(landmark, ind) in landmarks" :key="ind">
        <div
          @click="processSelection(ind)"
          style="
            width: 100%;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <Check
            v-bind:style="{
              visibility: selectedLandmarks.has(ind) ? 'visible' : 'hidden',
            }"
            class="w-6 h-6 text-mint-900"
          />
        </div>
        <div @click="displayPicsAndAddr(ind)">{{ landmark.name }}</div>
        <div
          v-bind:style="{
            'text-align': 'center',
            'background-color':
              selectedLandmarks.has(ind) && landmarksNotDone.has(ind)
                ? '#EBB572'
                : 'transparent',
          }"
        >
          {{ getNumSelectedPhotos(ind) }}
          <P>{{
            selectedLandmarks.has(ind) && landmarksNotDone.has(ind)
              ? "Not enough pics!"
              : ""
          }}</P>
        </div>
      </TableRow>
    </TableBody>
  </lob-table>
</template>

<script>
import "./LandmarksTable.css";
const Lob = require("lob")(process.env.VUE_APP_LOB_API_KEY);

export default {
  name: "LandmarksTable",
  data() {
    return {
      selectedLandmarks: new Set(),
      loaded: new Set(),
      photos: {},
      answers: {}, // an Object b/c don't know how many landmarks admin selects yet
      avResults: {},
    };
  },
  props: {
    landmarks: {
      type: Array,
      required: true,
    },
    lastSelectedInd: {
      type: Number,
      required: true,
    },
    landmarksNotDone: {
      type: Set,
      required: true,
    },
  },
  mounted() {
    if (this.$store.state.admin.photos) {
      this.loaded = new Set(Object.keys(this.$store.state.admin.photos));
      this.photos = this.$store.state.admin.photos;
    }
    if (this.$store.state.admin.selectedLandmarks)
      this.selectedLandmarks = this.$store.state.admin.selectedLandmarks;
  },
  methods: {
    displayPicsAndAddr(ind) {
      if (
        this.lastSelectedInd != null &&
        this.lastSelectedInd != ind &&
        (this.getNumSelectedPhotos(this.lastSelectedInd) < 3 ||
          this.getNumSelectedPhotos(this.lastSelectedInd) == "--")
      ) {
        // means admin didn't choose enough pics for last selected landmark
        this.$emit("addLandmarkUndone", this.lastSelectedInd, ind);
      }
      this.$emit("addLandmarkUndone", null, ind);

      if (!this.loaded.has(ind)) {
        this.$store.state.admin.service.getDetails(
          {
            placeId: this.landmarks[ind].reference,
            fields: ["photos"],
          },
          (place, status) => {
            if (status == window.google.maps.places.PlacesServiceStatus.OK) {
              if (place.photos && place.photos.length) {
                const photos = [];
                place.photos.forEach((photo) => {
                  photos.push({
                    url: photo.getUrl(),
                    html_attributions: photo.html_attributions,
                    name: this.landmarks[ind].name,
                  });
                });
                this.photos[ind] = photos;
              }
              this.loaded.add(ind);
            }
          }
        );

        // determine the "answer" address of this landmark with Lob's AV
        Lob.usVerifications.verify(
          {
            address: this.$store.state.admin.landmarks[ind].formatted_address,
          },
          (err, res) => {
            if (err)
              this.answers[ind] = `Error with Address Verification: ${err}`;
            else {
              this.avResults[ind] = res;
              if (!res.primary_line.length) this.answers[ind] = res.last_line;
              else {
                let finalAnswer = `${res.primary_line}`;
                if (res.secondary_line.length)
                  finalAnswer += ` ${res.secondary_line}`;
                finalAnswer += `, ${res.last_line}`;
                this.answers[ind] = finalAnswer;
              }
            }
          }
        );
      }
      this.$emit(
        "renderCardsAndAns",
        this.photos,
        this.answers,
        this.avResults,
        ind
      );
    },
    processSelection(ind) {
      if (this.selectedLandmarks.has(ind)) this.selectedLandmarks.delete(ind);
      else this.selectedLandmarks.add(ind);

      this.$store.commit({
        type: "admin/setSelectedLandmarks",
        selectedLandmarks: this.selectedLandmarks,
      });
      this.displayPicsAndAddr(ind);
    },
    getNumSelectedPhotos(ind) {
      const selectedPhotos = this.$store.state.admin.selectedPhotos;
      return selectedPhotos && selectedPhotos[ind]
        ? selectedPhotos[ind].size
        : "--";
    },
  },
};
</script>
