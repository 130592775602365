<template>
  <div
    style="
      background-color: #00002f;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <H1 id="luck">Best of Luck</H1>
  </div>
</template>

<script>
export default {
  name: "TransitionPage",
  emits: ["set-page"],
  mounted() {
    setTimeout(() => this.$emit("set-page", "QUESTION"), 500);
  },
};
</script>

<style>
#id {
  color: #e2f7ed;
  text-align: center;
}
</style>
