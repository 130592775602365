<template>
  <Home
    :sessionID="sessionID"
    v-if="page === 'HOME'"
    @set-page="setPage"
    @set-session-id="setSessionID"
  />
  <TutorialCarousel
    v-else-if="page === 'TUTORIALCAROUSEL'"
    @set-page="setPage"
  />
  <TransitionPage v-else-if="page === 'TRANSITION'" @set-page="setPage" />
  <Question v-else-if="page === 'QUESTION'" @set-page="setPage" />
  <CorrectResult v-else-if="page === 'CORRECTRESULT'" @set-page="setPage" />
  <IncorrectResult v-else-if="page === 'INCORRECTRESULT'" @set-page="setPage" />
  <NoAttempts v-else-if="page === 'NOATTEMPTS'" @set-page="setPage" />
  <MapQuestion v-else-if="page === 'MAPQUESTION'" @set-page="setPage" />
  <End v-else-if="page === 'END'" @set-page="setPage" />
  <EnterInfo v-else-if="page === 'ENTERINFO'" @set-page="setPage" />
  <Thanks v-else-if="page === 'THANKS'" @set-page="setPage" />
  <Error v-else />
</template>

<script>
import Home from "./Home.vue";
import TutorialCarousel from "./MobilePages/TutorialCarousel.vue";
import TransitionPage from "./MobilePages/TransitionPage.vue";
import Question from "./MobilePages/Question.vue";
import CorrectResult from "./MobilePages/CorrectResult.vue";
import IncorrectResult from "./MobilePages/IncorrectResult.vue";
import NoAttempts from "./MobilePages/NoAttempts.vue";
import MapQuestion from "./MobilePages/MapQuestion.vue";
import End from "./MobilePages/End.vue";
import Error from "./Error.vue";
import EnterInfo from "./MobilePages/EnterInfo.vue";
import Thanks from "./MobilePages/Thanks.vue";

export default {
  data() {
    return {
      page: "HOME",
      inputtedAddress: null,
      sessionID: null,
    };
  },
  components: {
    Home,
    TutorialCarousel,
    TransitionPage,
    Question,
    CorrectResult,
    IncorrectResult,
    NoAttempts,
    MapQuestion,
    End,
    EnterInfo,
    Thanks,
    Error,
  },
  methods: {
    setPage(page) {
      window.scrollTo(0, 0);
      this.page = page;
    },
    setSessionID(newSID) {
      this.sessionID = newSID;
    },
  },
};
</script>
