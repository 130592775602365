<template>
  <div class="enter-outer-container">
    <img
      class="enter-email-bg"
      src="../../assets/enter-email-bg.svg"
      alt="Blue Wave"
    />
    <div class="enter-inner-container">
      <img class="lob-logo-bg" src="../../assets/lob-logo.png" alt="Lob Logo" />
      <div class="enter-header-container">
        <span>Enter your name to verify you won:</span>
      </div>
      <div class="enter-content-container">
        <img
          class="person-walking-image"
          src="../../assets/person-walking.png"
          alt="Person Walking"
        />
      </div>
      <div class="enter-form-container">
        <text-input
          placeholder="Full Name"
          class="text-input"
          v-model="name"
          id="fullName"
          label=""
        />
        <text-input
          placeholder="Email Address"
          class="text-input"
          v-model="email"
          id="address"
          label=""
        />
        <div class="enter-checkbox-container">
          <input
            type="checkbox"
            id="checkbox"
            v-model="checked"
            style="margin-right: 1rem"
          />
          <label labelFor="checkbox" label="">
            I agree to receive email communications. I understand that by
            providing my email address and clicking the box above, I agree to
            receive emails from LOB.
          </label>
        </div>

        <lob-button
          class="enter-info-button"
          :disabled="isDisabled"
          @click="saveUser"
        >
          Submit
        </lob-button>

        <lob-button
          class="enter-info-button"
          @click="nextPage"
          style="margin-top: 8%; white-space: nowrap"
        >
          Next Page
        </lob-button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
const crypto = require("crypto");
import "./EnterInfo.css";
export default {
  name: "EnterInfo",
  emits: ["set-page"],
  data() {
    return {
      name: "",
      email: "",
      submitted: false,
      futureCommsConsent: false,
      checked: false,
    };
  },
  computed: {
    isDisabled: function () {
      return this.email && this.name && !this.checked;
    },
  },
  methods: {
    nextPage() {
      this.$emit("set-page", "THANKS");
    },
    async saveUser() {
      this.$emit("set-page", "THANKS");
      const nameCipher = crypto.createCipheriv(
        process.env.VUE_APP_ENCRYPT_ALGO,
        process.env.VUE_APP_ENCRYPT_KEY,
        process.env.VUE_APP_ENCRYPT_INIT
      );
      const nameEncrypted =
        nameCipher.update(this.name, "utf8", "hex") + nameCipher.final("hex");

      const emailCipher = crypto.createCipheriv(
        process.env.VUE_APP_ENCRYPT_ALGO,
        process.env.VUE_APP_ENCRYPT_KEY,
        process.env.VUE_APP_ENCRYPT_INIT
      );
      const emailEncrypted =
        emailCipher.update(this.email, "utf8", "hex") +
        emailCipher.final("hex");

      try {
        // add user to DB
        const docRef = await addDoc(collection(db, "users"), {
          event: this.$store.state.game.event,
          name: nameEncrypted,
          email: emailEncrypted,
          claimed: false,
          futureCommsConsent: this.futureCommsConsent,
        });
        console.log("Document written with ID: ", docRef.id);
        this.submitted = true;
      } catch (e) {
        console.error("Error adding events document: ", e);
      }
    },
  },
};
</script>
