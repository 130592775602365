import { createApp } from "vue/dist/vue.esm-bundler";
import router from "./router";
import { signOut, getAuth } from "./firebase";
import store from "./store";

import components from "@lob/ui-components";
import "@lob/ui-components/dist/ui-components.css";
import "./registerServiceWorker";

createApp({})
  .use(router)
  .use(store)
  .mixin({
    methods: {
      googleSignOut: async () => {
        signOut(getAuth());
        router.push("/");
      },
    },
  })
  .use(components)
  .mount("#app");
