/* more about copyrighting pics here:
https://law.stackexchange.com/questions/37291/are-the-images-pulled-from-the-google-places-api-allowed-to-be-used-on-our-websi/37292#:~:text=Those%20images%20are%20property%20of,without%20their%20(written)%20permission.&text=Photos%20returned%20by%20the%20Photo,owners%20and%20user%20contributed%20photos.&text=Copyright%20and%20intellectual%20property%20is,you%20accept%20it%20or%20not./
*/
<template>
  <div style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); transition: 0.3s">
    <div
      v-bind:style="{
        width: '190px',
        height: '190px',
        'background-image': 'url(' + url + ')',
        'background-color': '#cccccc',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }"
      @click="selectable && this.$emit('processPicSelect', ind)"
    >
      <Checkmark v-show="selected" class="w-6 h-6 text-primary-500" />
    </div>
    <div style="padding-top: 1rem">
      Source{{ html_attributions.length > 1 ? "s" : "" }}:
      <span
        v-for="(html_attr, ind) in html_attributions"
        :key="ind"
        v-html="html_attr"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    url: {
      type: String,
      required: true,
    },
    html_attributions: {
      type: Array,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    ind: {
      type: Number,
      required: false,
    },
    selectable: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
