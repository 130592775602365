<template>
  <div class="outer-container">
    <div class="upper-container">
      <div>
        <img
          class="lob-logo-image-white"
          src="../assets/Lob-Logo-white.png"
          alt="White Lob logo"
        />
        <img
          class="lob-logo-image-blue"
          src="../assets/lob-logo.png"
          alt="Blue Lob logo"
        />
      </div>
      <div class="travel-trivia-header-container-mobile">
        <span class="travel-trivia-header">Travel Trivia</span>
      </div>
      <div>
        <img
          class="earth-image"
          src="../assets/earth.png"
          alt="Earth with arrows"
        />
      </div>
    </div>
    <div class="overlay-warning-desktop hidden-for-mobile">
      <h1>Travel Trivia</h1>
      <alert v-show="err" variant="warning">
        <p>Please select your event.</p>
      </alert>
      <dropdown
        class="events-dropdown"
        id="events-dropdown"
        v-model="modelValue"
        :options="Object.keys(eventsCities)"
        placeholder="Event Name"
        v-if="selectedEventId.length == 0"

        label=""
      />
      <lob-button class="start-game-button" @click="startGame">
        START GAME
      </lob-button>
      <div
        class="admin-button button-admin-login"
        @click="this.$router.push('/login')"
      >
        ADMIN LOGIN
      </div>
    </div>
    <div
      class="lower-container hidden-for-desktop"
      :style="{
        padding: consentBannerVisible ? '15% 20% 20% 20%' : '20% 20% 5% 20%',
      }"
    >
      <div class="travel-trivia-header-container-desktop">
        <span class="travel-trivia-header">Travel Trivia</span>
      </div>
      <alert v-show="err" variant="warning">
        <p>Please select your event.</p>
      </alert>
      <dropdown
        class="events-dropdown hidden-for-desktop"
        id="events-dropdown"
        v-model="modelValue"
        :options="Object.keys(eventsCities)"
        placeholder="Event Name"
        v-if="selectedEventId.length == 0"
        label=""
      />
      <lob-button class="start-game-button" @click="startGame">
        START GAME
      </lob-button>
      <div
        class="admin-button button-admin-login"
        @click="this.$router.push('/login')"
      >
        ADMIN LOGIN
      </div>
    </div>
    <tracking-consent @close="consentBannerVisible = false" />
  </div>
</template>

<script>
import "./Home.css";
import { db, getAuth, signInAnonymously } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  setDoc,
  increment,
  doc,
} from "firebase/firestore";
import TrackingConsent from "./TrackingConsent.vue";

export default {
  name: "Home",
  components: {
    TrackingConsent,
  },
  data() {
    return {
      err: false,
      modelValue: "",
      eventsCities: {},
      selectedEventId: "",
      consentBannerVisible: !window.localStorage.getItem("tt-allow"),
    };
  },
  props: {
    sessionID: {
      type: String,
    },
  },
  async mounted() {
    // sign in anon to Firebase in order to get event names for dropdown
    await signInAnonymously(getAuth()).catch((err) => {
      console.error(err);
    });
    const q = query(collection(db, "events"), where("isVisible", '==', 'YES'));
    const querySnapshot = await getDocs(q)
    const res = {};
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      res[data.name] = { "city_data": data.city, "id": doc.id};
    });
    this.eventsCities = res;
    console.log(this.eventsCities)
    if (this.$route.query.game) {
      this.selectedEventId = this.$route.query.game
    }
    const localStorage = window.localStorage;
    if (localStorage.getItem("tt-allow")) {
      // user allows us to use their local storage for anonymized tracking purposes
      if (!localStorage.getItem("tt-sid")) {
        // new session
        const newSID = this.genSessionID(10);
        localStorage.setItem("tt-sid", newSID);

        this.incrementVisitorCount();
      }
    } else {
      if (!this.sessionID) {
        // new session
        const newSID = this.genSessionID(10);
        this.$emit("set-session-id", newSID);

        this.incrementVisitorCount();
      }
    }
  },
  methods: {
    async incrementVisitorCount() {
      // add another user count to analytics
      await setDoc(
        doc(db, "analytics", "anon_nums"),
        { visitors: increment(1) },
        { merge: true }
      ).catch((err) => console.error(err));
    },
    genSessionID(len) {
      const CHARS =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcedfghijklmnopqrstuvwxyz1234567890";
      const CHARS_LEN = CHARS.length;
      let res = "";

      for (let i = 0; i < len; i++)
        res += CHARS.charAt([Math.random() * CHARS_LEN]);

      return res;
    },
    startGame() {
      if (this.selectedEventId.length == 0){
        // we didn't have the query variable, so let's make sure the modal value is set
        if (!this.modelValue.length) {
          this.err = true;
          return;
        } else this.err = false;
      } else {
        for (let evtCity in this.eventsCities){
          if (this.selectedEventId === this.eventsCities[evtCity]['id']) {
            this.modelValue = evtCity
          }
        }
      }
      

      this.updateEventsStat();
      this.loadQuestions();
      this.$emit("set-page", "TUTORIALCAROUSEL");
    },
    async updateEventsStat() {
      await setDoc(
        doc(db, "analytics", "events_stats"),
        { [`${this.modelValue}_num_visitors`]: increment(1) },
        { merge: true }
      ).catch((err) => console.error(err));
    },
    async loadQuestions() {
      const q = query(
        collection(db, "cities"),
        where("city", "==", this.eventsCities[this.modelValue]['city_data'])
      );
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot)
      if (querySnapshot.size) {
        const {
          answers,
          landmarks,
          numQuestions,
          photos,
          avComps,
          avDelAnalysis,
          avLCS,
          avResultsFlat,
        } = querySnapshot.docs[0].data();

        // need to pick numQuestions random questions/landmarks
        // and 3 random photos from each index
        let range = [...Array(numQuestions).keys()];
        range.sort(() => 0.5 - Math.random());
        // now that numQuestions = numSelectedLandmarks, no need to slice
        // range = range.slice(0, numQuestions);

        const randomizedAnswers = [];
        const randomizedLandmarks = [];
        const randomizedPhotos = [];
        const randomizedAvResults = [];
        range.forEach((ind) => {
          randomizedAnswers.push(answers[ind]);
          randomizedLandmarks.push(landmarks[ind]);
          photos[ind].sort(() => 0.5 - Math.random());
          randomizedPhotos.push(photos[ind].slice(0, 3));
          randomizedAvResults.push({
            ...avResultsFlat[ind],
            components: avComps[ind],
            deliverability_analysis: avDelAnalysis[ind],
            lob_confidence_score: avLCS[ind],
          });
        });

        this.$store.commit({
          type: "game/setQuestions",
          avResults: randomizedAvResults,
          answers: randomizedAnswers,
          landmarks: randomizedLandmarks,
          photos: randomizedPhotos,
          numQuestions: numQuestions,
          attemptsLeft: 3,
          points: 0,
          currPoints: 0,
          event: this.modelValue,
        });
      }
    },
  },
};
</script>
