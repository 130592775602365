<template>
  <BackdropWave />
  <div class="my-container container-result">
    <H2 class="header message">{{ "Oops, try again!" }} </H2>

    <div class="container-character hidden-for-desktop">
      <img class="robot-girl" src="../../assets/robot_girl.png" />
    </div>
    <div class="flex-spacer hidden-for-mobile" />

    <div class="end-lower-inner-container">
      <div class="text-attempts">
        You have x more chances to guess the correct address.
      </div>
      <lob-button class="button-next" @click="nextPage">
        Guess Again
      </lob-button>
    </div>
  </div>
  <div class="container-character hidden-for-mobile">
    <img class="robot-girl" src="../../assets/robot_girl.png" />
  </div>
</template>

<script>
import "./Result.css";
import BackdropWave from "@/components/shared/BackdropWave";

export default {
  name: "Result",
  components: { BackdropWave },
  emits: ["set-page"],
  mounted() {
    this.$store.commit({ type: "game/decrementAttempts" });
    if (this.$store.state.game.attemptsLeft > 0) {
      document.querySelector(".text-attempts").innerHTML = `You have ${
        this.$store.state.game.attemptsLeft
      } more ${
        this.$store.state.game.attemptsLeft !== 1 ? "chances" : "chance"
      } to guess the correct answer`;
    } else {
      this.$emit("set-page", "NOATTEMPTS");
    }
  },
  methods: {
    nextPage() {
      if (this.$store.state.game.attemptsLeft > 0) {
        this.$emit("set-page", "QUESTION");
      }
    },
  },
};
</script>
