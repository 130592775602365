import { createStore } from "vuex";
import adminModule from "./modules/adminModule";
import gameModule from "./modules/gameModule";

const store = createStore({
  modules: {
    admin: adminModule,
    game: gameModule,
  },
});

export default store;
