<template>
  <div class="my-container">
    <logout-btn />
    <alert v-show="err" variant="error">
      <p>Error: Please re-enter event information on previous page.</p>
    </alert>

    <P class="text-5xl text-primary-300" style="padding-top: 5rem"
      >How many questions per user?</P
    >
    <P class="text-3xl text-primary-gray-500">
      Must be equal to the number of landmarks you'll select below.
    </P>
    <text-input
      type="number"
      id="num-questions"
      label="Number of Questions"
      placeholder="Input a number"
      v-model="numQuestions"
      required
      @input="numQuestionsChange"
    />
    <P class="text-5xl text-primary-300" style="padding-top: 2rem">
      Google found these landmarks in your city
    </P>
    <P>
      Click on a row to see what photos Google has for the corresponding
      landmark. To select a landmark to appear in your final question set, click
      on the corresponding box in the left column; a checkmark should appear.
      (Note: this means selecting photos for a landmark does not imply that
      you've chosen that landmark to be in your final question set.) The
      rightmost column will show how many photos you've chosen for a given
      landmark.
    </P>
    <P>
      You'll need to select (click) at least 3 photos for each of the landmarks
      that you'd like in your question set. On the game side, 3 of your photos
      for a given landmark will be randomly chosen for each question and for
      each player.
    </P>
    <H4 v-if="landmarkInd >= 0" style="width: 75%; padding-top: 2rem"
      >Lob AV-Sanitized Address for {{ landmarks[landmarkInd].name }}</H4
    >
    <H3 v-if="landmarkInd >= 0">{{ rawAnswers[landmarkInd] }}</H3>
    <grid
      v-if="landmarkInd >= 0"
      :photos="currPhotos"
      :landmarkInd="landmarkInd"
      @enough="remLandmarkDone"
    />
    <landmarks-table
      v-if="landmarks.length"
      :landmarks="landmarks"
      :lastSelectedInd="lastSelectedInd"
      :landmarksNotDone="landmarksNotDone"
      @addLandmarkUndone="addLandmarkUndone"
      @renderCardsAndAns="setPhotosAnswersAvResults"
      style="margin-top: 2rem"
    />

    <div class="powered-by-google" style="padding-top: 1rem">
      <img :src="require('@/assets/powered_by_google_on_white.png')" />
    </div>

    <alert v-show="nonPositiveErr" variant="error">
      <p>Error: Invalid non-positive integer for number of questions.</p>
    </alert>
    <alert v-show="unequalNumQuestions" variant="error">
      <p>
        Error: Number of Questions does not match number of selected Landmarks.
        To select a landmark, click on the left column of the corresponding row.
        A checkmark should appear upon selection.
      </p>
    </alert>
    <alert v-show="numPhotosErr" variant="error">
      <p>
        Error: One of your selected landmarks does not have 3 selected photos.
      </p>
    </alert>
    <lob-button @click="this.$router.push('/create_event')" class="back-btn">
      BACK
    </lob-button>
    <lob-button @click="confirm" class="next-btn"> CONFIRM </lob-button>
  </div>
</template>

<script>
import LogoutBtn from "./LogoutBtn.vue";
import LandmarksTable from "./LandmarksTable.vue";
import Grid from "./Grid.vue";

export default {
  name: "QuestionPage",
  data() {
    return {
      err: false,
      landmarks: [],
      service: null,
      rawPhotos: {},
      rawAnswers: {},
      rawAvResults: {},
      landmarkInd: -1,
      numQuestions: null,
      nonPositiveErr: false, // for when numQuestions is <= 0 or null
      unequalNumQuestions: false, // for when numQuestions > num selected landmarks
      numPhotosErr: false, // for when user doesn't choose 3 photos for each selected landmark

      lastSelectedInd: null, // want some historical record of last row admin clicked on
      // so when they click on another row, we can check whether the
      // last one has a sufficient number of photos selected
      landmarksNotDone: new Set(), // set of landmarks that admin hasn't chosen enough pics for
    };
  },
  components: {
    LogoutBtn,
    LandmarksTable,
    Grid,
  },
  methods: {
    addLandmarkUndone(lastSelectedInd, nextSelectedInd) {
      if (lastSelectedInd != null) this.landmarksNotDone.add(lastSelectedInd);
      this.lastSelectedInd = nextSelectedInd;
    },
    remLandmarkDone() {
      this.landmarksNotDone.delete(this.lastSelectedInd);
    },
    numQuestionsChange(num) {
      this.numQuestions = num;
    },
    setPhotosAnswersAvResults(photos, answers, avResults, ind) {
      this.rawPhotos = photos;
      this.rawAnswers = answers;
      this.rawAvResults = avResults;
      this.landmarkInd = ind;
    },
    validateNumPhotos() {
      const selectedLandmarks = this.$store.state.admin.selectedLandmarks; // Set
      const selectedPhotos = this.$store.state.admin.selectedPhotos; // Obj of Sets

      for (let landmarkInd of selectedLandmarks.values()) {
        if (!selectedPhotos || selectedPhotos[landmarkInd].size < 3) {
          this.numPhotosErr = true;
          return false;
        }
      }
      this.numPhotosErr = false;
      return true;
    },
    async confirm() {
      const num = parseFloat(this.numQuestions);
      if (Number.isInteger(num) && num > 0) {
        // positive int numQuestions
        this.nonPositiveErr = false;
        if (
          !this.$store.state.admin.selectedLandmarks ||
          num != this.$store.state.admin.selectedLandmarks.size
        ) {
          this.unequalNumQuestions = true;
          return;
        }
        this.unequalNumQuestions = false;
        // save to store
        this.$store.commit({
          type: "admin/setNumQuestions",
          numQuestions: num,
        });
      } else {
        this.nonPositiveErr = true;
        return;
      }
      if (!this.validateNumPhotos()) return;

      this.$router.push("/confirm");
    },
  },
  /*
    onMount, get landmarks in cityState then save both Google Places Library
    and resulting landmarks to store
    also, if we're back on this page after coming from Confirm, then we'd like to
    just draw some state values from the store rather than make the same requests
    all over again
  */
  mounted() {
    this.$nextTick(function () {
      if (this.$store.state.admin.landmarks)
        this.landmarks = this.$store.state.admin.landmarks;
      else {
        if (!this.$store.state.admin.service) {
          const service = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
          this.$store.commit({
            type: "admin/setService",
            service: service,
          });
        }

        if (!this.$store.state.admin.cityState) this.err = true;
        else this.err = false;

        this.$store.state.admin.service.textSearch(
          {
            query: `${this.$store.state.admin.cityState} landmarks`,
          },
          (res) => {
            this.landmarks = res;
            this.$store.commit({
              type: "admin/setLandmarks",
              landmarks: res,
            });
          }
        );
      }

      if (this.$store.state.admin.numQuestions)
        this.numQuestions = this.$store.state.admin.numQuestions.toString();

      if (this.$store.state.admin.photos)
        this.rawPhotos = this.$store.state.admin.photos;

      if (this.$store.state.admin.answers)
        this.rawAnswers = this.$store.state.admin.answers;
    });
  },
  beforeUnmount() {
    this.$store.commit({
      type: "admin/setPhotosAnswersAvResults",
      photos: this.rawPhotos,
      answers: this.rawAnswers,
      avResults: this.rawAvResults,
    });
  },
  computed: {
    currPhotos() {
      if (!this.rawPhotos[this.landmarkInd]) return [];
      return this.rawPhotos[this.landmarkInd];
    },
  },
};
</script>
