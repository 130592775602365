<template>
  <P>
    Questions for this city already exist in the database. Would you like to
    assign this city's questions to this event or delete this city's questions
    and create new ones?
  </P>

  <div
    style="
      display: flex;
      justify-content: space-around;
      align-content: center;
      margin: 2rem 0;
    "
  >
    <lob-button @click="toAssign = true">ASSIGN</lob-button>
    <lob-button @click="toDelete = true">DELETE</lob-button>
  </div>
  <Modal :visible="toDelete || toAssign" @close="closeModal">
    <template v-slot:header>
      <h4>{{ toAssign ? "Assignment" : "Deletion" }} Confirmation</h4>
    </template>
    <P>
      Are you sure you'd like to {{ toAssign ? "assign" : "delete" }} your new
      event to this city?
    </P>
    <P>
      {{
        toDelete
          ? "Note: This city will be deleted after you've created a new city of the same location."
          : ""
      }}
    </P>
    <template v-slot:footer>
      <div class="my-modal-footer">
        <lob-button @click="closeModal" variant="secondary">Cancel</lob-button>
        <lob-button @click="toAssign ? assignCity() : deleteCity()"
          >Confirm</lob-button
        >
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: "ExistingCities",
  data() {
    return {
      toAssign: false,
      toDelete: false,
    };
  },
  emits: ["assign"],
  methods: {
    closeModal() {
      this.toDelete = false;
      this.toAssign = false;
    },
    assignCity() {
      this.$emit("assign");
    },
    deleteCity() {
      // just move onto next page in event creation
      // and actually delete the city when the new city's about to be created
      // this way, don't need to worry about other events that're also relying on this city
      this.toDelete = false;
    },
  },
};
</script>
