<template>
  <div class="tutorial-outer-container">
    <div class="hidden-for-desktop">
      <div class="bg-tutorial2-container">
        <img
          class="bg-rocket"
          src="../../assets/person-on-rocket.png"
          alt="Person on Rocket"
        />
      </div>
      <div class="bg-tutorial2-lower-container">
        <span class="tutorial-bottom-heading rocket-heading"
          >Google Search can Help</span
        >
        <span class="tutorial-bottom-text rocket-text"
          >Google search can be a helpful tool when searching for the right
          address. If you’re feeling stumped, there will be 2 hints available to
          help.
        </span>
        <img
          class="tutorial-bg-1"
          src="../../assets/tutorial-bg-light-blue-2.svg"
          alt="Light Blue Wave"
        />
        <img
          class="tutorial-bg-2"
          src="../../assets/tutorial-bg-dark-blue-2.svg"
          alt="Blue Wave"
        />
      </div>
    </div>
    <div class="hidden-for-mobile">
      <div class="bg-tutorial2-container">
        <img class="lob-logo" src="../../assets/lob-logo.png" alt="Lob Logo" />
        <img
          class="bg-tutorial2-cloud-1"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="bg-tutorial2-cloud-2"
          src="../../assets/cloud.png"
          alt="Cloud"
        />
        <img
          class="bg-rocket"
          src="../../assets/desktop-person-on-rocket.png"
          alt="Person on Rocket"
        />
        <span class="tutorial2-text">Put Your Search Skills to the Test</span>
      </div>
      <div class="bg-tutorial-lower-container">
        <span class="tutorial-bottom-heading">Google Search can Help</span>
        <span class="tutorial-bottom-text rocket-text"
          >Google search can be a helpful tool when searching for the right
          address. If you’re feeling stumped, there will be 2 hints available to
          help.
        </span>
        <img
          class="tutorial-bg-1"
          src="../../assets/desktop-tutorial-bg-light-blue-2.svg"
          alt="Light Blue Wave"
        />
        <img
          class="tutorial-bg-2"
          src="../../assets/desktop-tutorial-bg-dark-blue-1.svg"
          alt="Blue Wave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./Tutorial2.css";

export default {
  name: "Tutorial2",
  emits: ["set-page"],
};
</script>
