import * as firebase from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  signInAnonymously,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyBGO6ehYpWtBnIg76JaIifdoa80p5OqXn8",
  authDomain: "expo-game-89228.firebaseapp.com",
  projectId: "expo-game-89228",
  storageBucket: "expo-game-89228.appspot.com",
  messagingSenderId: "284601649023",
  appId: "1:284601649023:web:238d1636f68679d0d57c4a",
  measurementId: "G-GXK5777BDE",
};
firebase.initializeApp(firebaseConfig);
const db = getFirestore();

// export utils/refs
export {
  db,
  onAuthStateChanged,
  getAuth,
  signInWithPopup,
  signInAnonymously,
  GoogleAuthProvider,
  signOut,
};
