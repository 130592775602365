<template>
  <BackdropWave />
  <div class="my-container container-result">
    <H2 class="header message">{{ "Great job!" }} </H2>
    <img id="cloud" src="../../assets/cloud.png" />

    <div class="container-character hidden-for-desktop">
      <img src="../../assets/balloon-man.png" />
    </div>

    <div class="end-lower-inner-container">
      <div class="text-attempts">
        You guessed the address correctly. You have gained 5 points!
      </div>
      <lob-button class="button-next" @click="nextPage"> Next </lob-button>
    </div>
  </div>
  <div class="container-character hidden-for-mobile">
    <img src="../../assets/balloon-man.png" />
  </div>
</template>

<script>
import "./Result.css";
import BackdropWave from "@/components/shared/BackdropWave";

export default {
  name: "Result",
  components: { BackdropWave },
  emits: ["set-page"],
  data() {
    return {
      photoObjs: [],
    };
  },
  mounted() {
    document.querySelector(
      ".text-attempts"
    ).innerHTML = `You guessed the address correctly. You have gained ${this.$store.state.game.currPoints} points!`;
  },
  methods: {
    nextPage() {
      this.$store.commit({ type: "game/restoreAttemptsHints" });
      this.$emit("set-page", "MAPQUESTION");
    },
  },
};
</script>

<style>
#cloud {
  position: absolute;
  top: 11rem;
  left: -2rem;
  width: 10%;
}

@media only screen and (max-width: 400px) {
  img #cloud {
    visibility: false;
  }
}

@media (min-width: 640px) {
  .end-lower-inner-container {
    bottom: 3rem;
    position: absolute;
  }
}

@media (max-width: 969px) {
  .text-attempts {
    width: 40%;
  }
}
</style>
