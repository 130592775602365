<template>
  <BackdropWave />
  <div class="my-container container-result">
    <H2 class="header message">That was a tough one!</H2>

    <div class="container-character hidden-for-desktop">
      <img src="../../assets/saly14.png" />
    </div>
    <div class="flex-spacer hidden-for-mobile" />

    <div class="end-lower-inner-container">
      <div class="text-attempts">
        Sorry, you have run out of chances to guess.
      </div>
      <lob-button class="button-next" @click="nextPage">
        Correct Answer
      </lob-button>
    </div>
  </div>
  <div class="container-character hidden-for-mobile">
    <img src="../../assets/saly14.png" />
  </div>
</template>

<script>
import "./Result.css";
import BackdropWave from "@/components/shared/BackdropWave";

export default {
  name: "Result",
  components: { BackdropWave },
  emits: ["set-page"],
  data() {
    return {
      photoObjs: [],
    };
  },
  methods: {
    nextPage() {
      this.$store.commit({ type: "game/restoreAttemptsHints" });
      this.$emit("set-page", "MAPQUESTION");
    },
  },
};
</script>

<style>
@media only screen and (max-width: 400px) {
}
</style>
