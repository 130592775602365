<template>
  <swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="30"
    :pagination="{ clickable: true }"
  >
    <swiper-slide>
      <Tutorial1 />
      <lob-button class="lets-play-button" @click="nextSlide">
        Next
      </lob-button>
    </swiper-slide>
    <swiper-slide>
      <Tutorial2 />
      <lob-button class="lets-play-button" @click="nextSlide">
        Next
      </lob-button>
    </swiper-slide>
    <swiper-slide>
      <Tutorial3 />
      <lob-button class="lets-play-button" @click="nextPage"
        >Let's Play</lob-button
      >
    </swiper-slide>
  </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
import Tutorial1 from "./Tutorial1.vue";
import Tutorial2 from "./Tutorial2.vue";
import Tutorial3 from "./Tutorial3.vue";

import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    Tutorial1,
    Tutorial2,
    Tutorial3,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  methods: {
    nextPage() {
      this.$emit("set-page", "TRANSITION");
      return;
    },
    nextSlide() {
      const swiper = document.querySelector(".swiper").swiper;
      swiper.slideNext();
    },
  },
};
</script>
