import { createRouter, createWebHashHistory } from "vue-router";

import MobileManager from "./components/MobileManager.vue";
import LoginPage from "./components/AdminPages/LoginPage.vue";
import EventsPage from "./components/AdminPages/EventsPage.vue";
import CreateEventPage from "./components/AdminPages/CreateEventPage.vue";
import EventStatsPage from "./components/AdminPages/EventStatsPage.vue";
import EventEntry from "./components/AdminPages/EventEntry.vue";
import QuestionPage from "./components/AdminPages/QuestionPage.vue";
import ConfirmPage from "./components/AdminPages/ConfirmPage.vue";
import SuccessPage from "./components/AdminPages/SuccessPage.vue";

import { onAuthStateChanged, getAuth } from "./firebase";

const routes = [
  { path: "/", component: MobileManager },
  { path: "/login", component: LoginPage, meta: { guest: true } },
  { path: "/events", component: EventsPage, meta: { auth: true } },
  { path: "/event/:eventID", component: EventEntry, meta: { auth: true} },
  { path: "/create_event", component: CreateEventPage, meta: { auth: true } },
  { path: "/event_stats", component: EventStatsPage, meta: { auth: true } },
  { path: "/questions", component: QuestionPage, meta: { auth: true } },
  { path: "/confirm", component: ConfirmPage, meta: { auth: true } },
  { path: "/success", component: SuccessPage, meta: { auth: true } },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// https://softauthor.com/firebaseui-vue-facebook-google-auth/#sign-in-with-google-using-firebaseui
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) next();
      else next({ path: "/login" });
    });
  } else if (to.matched.some((record) => record.meta.guest)) {
    onAuthStateChanged(getAuth(), (user) => {
      if (user && !user.isAnonymous) next({ path: "/events" });
      else next();
    });
  } else next();
  // https://css-tricks.com/protecting-vue-routes-with-navigation-guards/
  // for redirection messages
});

export default router;
